import { Injectable, EventEmitter, Inject, PLATFORM_ID } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { isPlatformServer } from "@angular/common";
import { BehaviorSubject, Subscription, Subject } from "rxjs";

//SERVICES
import { EndpointService } from "./endpoint.service";
import { AuthService } from "../auth/auth.service";
import { ChatService } from "../chat.service";
import { RoutingService } from "../routing/routing.service";
import { SwapAccountComponent } from "app/swap-account/swap-account.component";
import { GenericContainerComponent } from "../../generic-container/generic-container.component";
import { NgxFreshChatService } from "ngx-freshchat";
import { DomSanitizer } from "@angular/platform-browser";
import { map } from "rxjs/internal/operators/map";
import { Values } from '../../../values/values';
import { id } from "date-fns/locale";

const ID_FUNCTIONAL_TYPE_TAB = 8;
const ID_FUNCTIONAL_TYPE_EXPANSION = 10;
const ID_FUNCTIONAL_TYPE_FORM = 11;

@Injectable({
  providedIn: "root",
})
export class GenericService {
  public projectID = 0;
  public applicationVersion = Values.Version;
  public applicationVersionDate = Values.VersionDate;

  public parentStructure = [];
  public renderedComponents = {};
  public structure = null;
  public formsIds = [];
  public structureTab = null;
  private inputValues: any;

  public serviceLanguageGeneric = new Map();
  public notHasBeenDuplicated: boolean = true;
  private lastManagedChanges = 500;
  private lastNameDuplicated: string;
  private lastParent: string;
  private lastOld: string;
  private elemsToDuplicate: string[];
  private lastLabel: string;
  public numTimesFormsChanged = 0;
  private duplicatedIds = {};
  public fileUploaded = false;
  private toolsCache = {};

  public menuStructure = null;
  public showAllInfo = false;
  public editingModeByUser = false;
  public editingModeByCompany = false;
  public historyChangesBool = false;

  public deletedFaByUser = {};
  public deletedFaByCompany = {};
  public userMain = false;
  public usersPersonalization = false;
  public elementsThatAffectOthers;
  public elementsThatAffectOthersHeader;
  public elementsThatAffectOthersFooter;
  public affectedElements;
  public affectedElementsHeader;
  public affectedElementsFooter;
  public affectedElementsFA = {};
  public selectOptions = [];
  public selectOptionsHeader = [];
  public selectOptionsFooter = [];
  public queriesInfoDebbug = [];
  public timmingsInfoDebbug = [];
  public hideColumnsTable = [];
  private data: {};
  public tableData;
  public dsbStructureOpened = [];
  private tableDataHeader;
  private tableDataFooter;
  public elementsArray = [];
  //public renderedFinished = false;
  public statusFunctionalAreas = {};
  public intialFunctionalAreas = {};
  public history = [];
  public historyIndex = -1;
  public redirectByTools = false;
  public genericContainerInstance: GenericContainerComponent;
  private functional_area_changeds = {};
  public paramControlVariables = {};
  public paramControlVariablesFAs = {};
  public windowChanges = {};
  public snackbarOpened = false;
  public staticOtherLanguages = [];

  public splittedWindow = false;

  public preLoadedElementsGeneric = {};

  public arrayFunctionalParentsLoaded = [];

  structureUpdated: EventEmitter<{}> = new EventEmitter();
  public refreshStructureFinished = true;
  public currentInitialArea;
  public containerFunctionArea;

  public warningDialog;
  public warningMessage;
  public warningTitle;
  public warningButton1;
  public warningButton2;
  public readMode = false;

  public lastMenuClick = null;
  public lastListClick = {};
  public headerId = 89;
  public headerNoLoguedId = 707;
  public footerId = 7118;
  public footerNoLoguedId = 7119;
  public finishedLoadStructure = false;
  public finishedLoadStructureHeader = false;
  public finishedLoadStructureFooter = false;
  //public elementsArrayPrevExternalWindow = null;
  public containerFunctionAreaPrevExternalWindow = null;
  public pendingRevertPrevExternalWindow = false;
  public lastExternalWindowModuleId = 0;
  public firstLoad = true;
  public previewModeUser = false;
  public devModeGeneric = false;
  public devModeGenericChanged = false;
  public tabChanging = false;
  public lastDeferContainer = -1;

  public dataValidityPendingToExecute = {};

  public breadcrumbs = [];
  public urlFullInfo = [];
  public openHelpExpansionName: string | undefined;

  public highlightHeaderElements = [];

  public originalInputsValues = {};

  lastPosition: string = "";

  menuVisibilityChange: Subject<string> = new Subject<string>();

  public updateFormFields: Subject<boolean> = new Subject<boolean>();

  public heightTableChange = false;
  public selectOptionsChange = false;
  public selectOptionsChangeEvent: Subject<boolean> = new Subject<boolean>();
  public executeNewFunction = new Subject<any>();
  public executeNewFunctionSubscription: Subscription;

  public iconFacesArray3 = [
    { icon: "sentiment_very_dissatisfied", color: "#E30B31" },
    { icon: "sentiment_neutral", color: "#FFB200" },
    { icon: "sentiment_very_satisfied", color: "#44C744" },
  ];
  public iconFacesArray5 = [
    { icon: "sentiment_very_dissatisfied", color: "#E30B31" },
    { icon: "sentiment_dissatisfied", color: "#FF6B00" },
    { icon: "sentiment_neutral", color: "#FFB200" },
    { icon: "sentiment_satisfied", color: "#017F01" },
    { icon: "sentiment_very_satisfied", color: "#44C744" },
  ];

  public tablesActualRows = [];

  public defaultNoImage = "https://movin.cloud/assets/img/no-image.png";

  public formsChanged = {};
  public actualFunctionFinish = true;

  public staticHTML = false;

  public parentStructureArray = [];
  public footerStructureArray = [];
  public headerStructureArray = [];
  public externalWindowStructureArray = [];
  public dsbStructureArray = [];

  public parentStructureArrayFA = [];
  public footerStructureArrayFA = [];
  public headerStructureArrayFA = [];
  public externalWindowStructureArrayFA = [];
  public dsbStructureArrayFA = [];

  public parentStructureArrayFields = [];
  public footerStructureArrayFields = [];
  public headerStructureArrayFields = [];
  public externalWindowStructureArrayFields = [];
  public dsbStructureArrayFields = [];

  public parentStructureArrayQueries = [];
  public footerStructureArrayQueries = [];
  public headerStructureArrayQueries = [];
  public externalWindowStructureArrayQueries = [];
  public dsbStructureArrayQueries = [];

  public openInEditMode = false; // Used to indicate if a page is call in EdithMode or not
  public commercialVideoconferenceId;
  public clientToCall;
  public webpSupported = false;

  public headHeight = 41.6;
  public scrollValue = 0;
  private scrollPostionY = new BehaviorSubject<number>(this.scrollValue);
  scrollPostionY$ = this.scrollPostionY.asObservable();

  public paymentImport = 0.00;

  public advicebarArray = {};
  private advicebarId = 9999;

  constructor(
    private endpointService: EndpointService,
    public authService: AuthService,
    public chatService: ChatService,
    private routingService: RoutingService,
    private formBuilder: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private location: Location,
    public dialog: MatDialog,
    private router: Router,
    public chat: NgxFreshChatService,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.menuVisibilityChange.subscribe((value) => {
      this.lastPosition = value;
    });
    this.clientToCall = null;
    this.commercialVideoconferenceId = null;
  }

  toggleMenuVisibility(position) {
    this.menuVisibilityChange.next(position);
  }

  isStructureNotLoaded(): boolean {
    return this.structure === null;
  }

  getNavigatorLang() {
    const lang = navigator.language;

    if (lang.includes("es")) return 1;
    else if (lang.includes("cat")) return 2;
    else if (lang.includes("en")) return 3;

    return 1; // default lang
  }

  loadData(moduleId, isExternal = false) {
    let urlWithoutHashtag = null;
    let urlHashtag = moduleId;
    let preLoadedElementsGenericKeys = Object.keys(this.preLoadedElementsGeneric);
    if (!isExternal) {
      urlWithoutHashtag = this.routingService.urlWithoutHashtag;
      urlHashtag = this.routingService.urlHashtag;
    }
    return {
      moduleId: +moduleId,
      languageId: localStorage.getItem("_ul_") || this.getNavigatorLang(),
      idCompanyGeneric: this.authService.getIdCompany(true),
      idUser: this.authService.getLoggedInUserId(),
      URL: urlWithoutHashtag,
      URLID: urlHashtag,
      idProfile: this.authService.profileIdGeneric,
      dsbType: 0,
      //idCompany es el idEmpresa y el idCompanyType es si es marca(1) o empresa(2) (los id de los servicios se cogen en backend)
      dsbId: 0,
      lastModule: this.routingService.previousModule,
      isExternal: isExternal,
      preLoadedElementsGenericKeys: preLoadedElementsGenericKeys
    };
  }

  emptyStructuresArrays(from) {
    if (from == 0) {
      this.parentStructureArray = [];
      this.parentStructureArrayFA = [];
      this.parentStructureArrayFields = [];
      this.parentStructureArrayQueries = [];
    }
    if (from == 0 || from == 1) {
      this.externalWindowStructureArray = [];
      this.externalWindowStructureArrayFA = [];
      this.externalWindowStructureArrayFields = [];
      this.externalWindowStructureArrayQueries = [];
    }

    this.dsbStructureArray = [];
    this.dsbStructureArrayFA = [];
    this.dsbStructureArrayFields = [];
    this.dsbStructureArrayQueries = [];
  }

  isObject(variable: any) {
    return typeof variable === 'object' && variable !== null && !Array.isArray(variable) && variable.length === undefined;
  };

  fetchStructure(moduleId: number): any {
    let firstKey: any;
    this.data = this.loadData(moduleId);
    this.emptyStructuresArrays(0);
    let routeAux = "";
    // console.log("ENRTROROROR", moduleId, this.data);
    return this.endpointService.getStructure(this.data, this.paramControlVariables).pipe(
      map((data) => {
        if (moduleId == 0) moduleId = data["response"]["idModule"];
        this.actualFunctionFinish = true;
        this.projectID = data["response"]["projectID"];
        this.editingModeByCompany = false;
        this.editingModeByUser = false;
        if (data["response"]["deletedFaByCompany"] && this.isObject(data["response"]["deletedFaByCompany"])) this.deletedFaByCompany = data["response"]["deletedFaByCompany"];
        else this.deletedFaByCompany = {};
        if(data["response"]["deletedFaByUser"] && this.isObject(data["response"]["deletedFaByUser"])) this.deletedFaByUser = data["response"]["deletedFaByUser"];
        else this.deletedFaByUser = {};
        this.userMain = data["response"]["userMain"];
        this.usersPersonalization = data["response"]["usersPersonalization"];
        this.applicationVersion = data["response"]["applicationVersion"];
        this.firstLoad = true;
        if (data["response"]["checkUserIsDeveloping"] && data["response"]["checkUserIsDeveloping"] == 1) this.authService.checkUserIsDeveloping = 1;
        if (!this.devModeGenericChanged) this.devModeGeneric = this.authService.checkUserIsDeveloping == 1;
        firstKey = Object.keys(data["response"]["structure"])[0];
        this.structure = data["response"]["structure"][firstKey];
        this.formsIds = data["response"]["forms"];
        this.currentInitialArea = {
          key: data["response"]["structure"][firstKey]["id_functional_area"],
          value: data["response"]["structure"][firstKey]["internal_name"],
        };
        if (this.staticHTML && data["response"]["dataAccount"]["idUser"] > 0) {
          this.authService.userId = data["response"]["dataAccount"]["idUser"];
          localStorage.setItem("_in_", null);
          this.getUserInfo(2, data["response"]["dataAccount"]["idCompanyGeneric"]);
          this.getCompanyColors();
        }
        this.authService.languageId = data["response"]["languageId"];
        localStorage.setItem("_ul_", this.authService.languageId.toString());
        this.authService.labelLanguage = data["response"]["labelLanguage"];
        this.authService.arrayLanguages = data["response"]["languagesInfo"];
        this.routingService.url = data["response"]["url"];
        this.urlFullInfo = data["response"]["urlFullInfo"];
        if (!this.staticHTML) this.readMode = data["response"]["structure"][firstKey]["readMode"];

        this.parentStructure = this.structure['child'];

        //this.elementsArray = data["response"]["elements"]
        this.assignClass(data["response"]["structure"][firstKey], 1);
        this.containerFunctionArea = data["response"]["structure"][firstKey];

        this.paramControlVariables = data["response"]["params"];

        if (data["response"]['error'] == -1) {
          this.snackBar.open('¡Vaya, parece que no has iniciado sesión!', 'X', {
            duration: 3000,
            panelClass: ['red-snackbar']
          });
          routeAux = "/" + this.authService.labelLanguage + '/login';
          this.location.replaceState(routeAux);
          this.routingService.changeUrl(routeAux);          

        } else if (data["response"]['error'] == -2 && data['response']['idModule'] != this.footerId) {
          this.snackBar.open('¡Vaya, parece que no tienes permisos para acceder a esta pantalla!', 'X', {
            duration: 3000,
            panelClass: ['red-snackbar']
          });
          routeAux = "/" + this.authService.labelLanguage + '/inicio';
          this.location.replaceState(routeAux);
          this.routingService.changeUrl(routeAux);

        } else if (data["response"]['error'] == -3) {
          this.snackBar.open('¡Vaya, parece que este usuario no existe o se encuentra inactivo!', 'X', {
            duration: 3000,
            panelClass: ['red-snackbar']
          });

        } else if (data["response"]['error'] == -4 && data['response']['idModule'] != this.footerId) {
          this.snackBar.open('¡Debes fichar antes de empezar a trabajar!', 'X', {
            duration: 3000,
            panelClass: ['red-snackbar']
          });

        } else if (data['response']['error'] == -999) {
          let panelClass = [];
          if (this.routingService.shouldOpenExternalWindow) panelClass.push('overAllDialog');
          const dialogRef = this.dialog.open(SwapAccountComponent, {
            width: 'auto',
            height: 'auto',
            panelClass: panelClass
          });

          dialogRef.afterClosed().subscribe((data) => {
            if (data.cancel) {
              if (data.reload) {
                this.router.navigate([""]).then(() => {
                  this.refreshStructure(2);
                });
              }
            } else {
              this.changeAccount(data.index);
              if (
                this.router.url ===
                "/" + this.authService.labelLanguage + "/inicio"
              ) {
                this.refreshStructure(2);
              } else {
                this.router.navigate([""]).then(() => {
                  this.refreshStructure(2);
                });
              }
            }
          });
        }

        if (this.staticHTML && data['response']['staticInfoEmpresa'] && data['response']['staticInfoEmpresa'] !== undefined && data['response']['staticInfoEmpresa'].length && data['response']['staticInfoEmpresa'].length > 0) {
          this.authService.accountsGeneric = data['response']['staticInfoEmpresa'];
          this.addOldParamsBackend();
          this.changeAccount(0, false, false);
        }

        if(this.staticHTML && data['response']['staticOtherLanguages'] && data['response']['staticOtherLanguages'].length && data['response']['staticOtherLanguages'].length > 0) {
          this.staticOtherLanguages = data['response']['staticOtherLanguages'];
        }

        if (data["response"]["structure"][firstKey]["id_functional_area"] == this.headerId ||
          data["response"]["structure"][firstKey]["id_functional_area"] == this.headerNoLoguedId ||
          data["response"]["structure"][firstKey]["id_functional_area"] == this.footerId ||
          data["response"]["structure"][firstKey]["id_functional_area"] == this.footerNoLoguedId
        ) {
          this.elementsThatAffectOthers = data["response"]["elementsThatAffectOthers"];
          this.elementsThatAffectOthersHeader = data["response"]["elementsThatAffectOthers"];
          this.elementsThatAffectOthersFooter = data["response"]["elementsThatAffectOthers"];

          this.affectedElements = data["response"]["affectedElements"];
          this.affectedElementsHeader = data["response"]["affectedElements"];
          this.affectedElementsFooter = data["response"]["affectedElements"];

          this.selectOptions = data["response"]["results"];
          this.selectOptionsHeader = data["response"]["results"];
          this.selectOptionsFooter = data["response"]["results"];

          this.tableData = data["response"]["tableData"];
          this.tableDataHeader = data["response"]["tableData"];
          this.tableDataFooter = data["response"]["tableData"];
          if (data["response"]["queriesInfoDebbug"]) this.queriesInfoDebbug = data["response"]["queriesInfoDebbug"];
          if (data["response"]["timmingsInfoDebbug"]) this.timmingsInfoDebbug = data["response"]["timmingsInfoDebbug"];
          if (data["response"]["hideColumnsTable"]) this.hideColumnsTable = data["response"]["hideColumnsTable"];
        } else {
          if (this.elementsThatAffectOthersHeader && Object.keys(this.elementsThatAffectOthersHeader).length === 0 && Object.getPrototypeOf(this.elementsThatAffectOthersHeader) === Object.prototype) {
            this.elementsThatAffectOthers =
              data["response"]["elementsThatAffectOthers"];
          } else {
            this.elementsThatAffectOthers = {
              ...this.elementsThatAffectOthersHeader,
              ...data["response"]["elementsThatAffectOthers"],
            };
          }

          if (this.affectedElementsHeader && Object.keys(this.affectedElementsHeader).length === 0 && Object.getPrototypeOf(this.affectedElementsHeader) === Object.prototype) {
            this.affectedElements = data["response"]["affectedElements"];
          } else {
            this.affectedElements = {
              ...this.affectedElementsHeader,
              ...data["response"]["affectedElements"],
            };
          }

          if (this.selectOptionsHeader && Object.keys(this.selectOptionsHeader).length === 0 && Object.getPrototypeOf(this.selectOptionsHeader) === Object.prototype) {
            this.selectOptions = data["response"]["results"];
          } else {
            this.selectOptions = {
              ...this.selectOptionsHeader,
              ...data["response"]["results"],
            };
          }

          if (this.tableDataHeader && Object.keys(this.tableDataHeader).length === 0 && Object.getPrototypeOf(this.tableDataHeader) === Object.prototype) {
            this.tableData = data["response"]["tableData"];
          } else {
            this.tableData = {
              ...this.tableDataHeader,
              ...data["response"]["tableData"],
            };
          }
          if (data["response"]["queriesInfoDebbug"]) this.queriesInfoDebbug = data["response"]["queriesInfoDebbug"];
          if (data["response"]["timmingsInfoDebbug"]) this.timmingsInfoDebbug = data["response"]["timmingsInfoDebbug"];
          if (data["response"]["hideColumnsTable"]) this.hideColumnsTable = data["response"]["hideColumnsTable"];
          this.setWindowChanges(moduleId, data["response"]["windowChanges"]);
        }
        let struct = [];
        if (struct['child'] !== undefined) struct = this.structure['child'];
        else struct = [this.structure];
        //if (this.structure !== undefined) this.asignFrontend(struct, moduleId);
        //this.findFormAttributesLoop(struct);
        //this.findFormAttributesInit();
        //this.checkPreviewModeGeneric();
        //this.checkDevModeGeneric();
        this.initElementInFrontend(this.structure, moduleId, this.structure);
        this.finishLoadedStructure(moduleId);
        if (!this.staticHTML && this.structure["id_functional_area"] != this.headerId && this.structure["id_functional_area"] != this.headerNoLoguedId && this.structure["id_functional_area"] != this.footerId && this.structure["id_functional_area"] != this.footerNoLoguedId) {
          let infoTab = {
            currentInitialArea: this.currentInitialArea,
            structure: this.structure,
            affectedElements: this.affectedElements,
            elementsThatAffectOthers: this.elementsThatAffectOthers,
            selectOptions: this.selectOptions,
            tableData: this.tableData,
            queriesInfoDebbug: this.queriesInfoDebbug,
            timmingsInfoDebbug: this.timmingsInfoDebbug,
            hideColumnsTable: this.hideColumnsTable,
            parentStructureArray: this.parentStructureArray,
            parentStructureArrayFA: this.parentStructureArrayFA,
            parentStructureArrayFields: this.parentStructureArrayFields,
            parentStructureArrayQueries: this.parentStructureArrayQueries,
            formsIds: this.formsIds
          }
          let id_pantalla = this.structure["id_functional_parent_initial"];
          let indexStruct = this.paramControlVariables[id_pantalla]["indexParam"];
          if (!this.arrayFunctionalParentsLoaded.hasOwnProperty(id_pantalla)) {
            this.arrayFunctionalParentsLoaded[id_pantalla] = [];
          }
          this.arrayFunctionalParentsLoaded[id_pantalla][indexStruct] = infoTab;
        }
        if (moduleId == this.headerNoLoguedId || moduleId == this.headerId) return this.structure;
        if (moduleId == this.footerNoLoguedId || moduleId == this.footerId) return this.structure;
        else return data["response"];
      }));
  }

  fetchStructureExternalWindow(moduleId: number, refresh = false): any {
    console.log("heeeeey");
    this.emptyStructuresArrays(1);
    let firstKey: any;
    this.dialog.closeAll();
    this.data = this.loadData(moduleId, true);
    if (!refresh) this.lastExternalWindowModuleId = moduleId;
    console.log("heeeeey 22");
    return this.endpointService.getStructure(this.data, this.paramControlVariables, this.routingService.paramsExternalWindow).subscribe((data) => {
      let shouldOpenExternalWindow = this.cloneVariable(this.routingService.shouldOpenExternalWindow)
      if (this.routingService.shouldOpenExternalWindow) {
        this.pendingRevertPrevExternalWindow = true;
        this.actualFunctionFinish = true;
        this.projectID = data["response"]['projectID'];
        this.applicationVersion = data["response"]["applicationVersion"];
        if (data["response"]['checkUserIsDeveloping'] && data["response"]['checkUserIsDeveloping'] == 1) this.authService.checkUserIsDeveloping = 1;
        if (!this.devModeGenericChanged) this.devModeGeneric = this.authService.checkUserIsDeveloping == 1;
        firstKey = Object.keys(data["response"]['structure'])[0];
        this.structure = data["response"]['structure'][firstKey];
        this.formsIds = data["response"]["forms"];
        this.routingService.externalWindowStructure = data["response"]['structure'];
        if (!this.staticHTML && this.firstLoad) {
          this.routingService.readModePrevExternalWindow = this.cloneVariable(this.readMode);
          this.readMode = data["response"]['structure'][firstKey]['readMode'];
        }
        if (this.firstLoad) {
          this.routingService.currentInitialAreaPrevExternalWindow = this.cloneVariable(this.currentInitialArea);
          this.currentInitialArea = { key: data["response"]['structure'][firstKey]['id_functional_area'], value: data["response"]['structure'][firstKey]['internal_name'] };
          this.routingService.parentStructurePrevExternalWindow = this.cloneVariable(this.parentStructure);
          //this.elementsArrayPrevExternalWindow = this.cloneVariable(this.elementsArray);
          this.parentStructure = this.structure;
          //this.elementsArray = data["response"]["elements"]
          this.containerFunctionAreaPrevExternalWindow = this.cloneVariable(this.containerFunctionArea);
          this.containerFunctionArea = data["response"]['structure'][firstKey];
        }

        this.assignClass(data["response"]['structure'][firstKey], 1);
        this.authService.languageId = data["response"]["languageId"];
        localStorage.setItem("_ul_", this.authService.languageId.toString());
        this.paramControlVariables = data["response"]["params"];

        if (this.firstLoad && !refresh) {
          if (this.routingService.elementsThatAffectOthersPrevExternalWindow == null) this.routingService.elementsThatAffectOthersPrevExternalWindow = this.cloneVariable(this.elementsThatAffectOthers);
          if (this.routingService.affectedElementsPrevExternalWindow == null) this.routingService.affectedElementsPrevExternalWindow = this.cloneVariable(this.affectedElements);
          if (this.routingService.selectOptionsPrevExternalWindow == null) this.routingService.selectOptionsPrevExternalWindow = this.cloneVariable(this.selectOptions);
          if (this.routingService.tableDataPrevExternalWindow == null) this.routingService.tableDataPrevExternalWindow = this.cloneVariable(this.tableData);
        }
        this.elementsThatAffectOthers = data["response"]["elementsThatAffectOthers"];
        this.affectedElements = data["response"]["affectedElements"];
        this.selectOptions = data["response"]["results"];
        this.tableData = data["response"]["tableData"];
        if (data["response"]["queriesInfoDebbug"]) this.queriesInfoDebbug = data["response"]["queriesInfoDebbug"];
        if (data["response"]["timmingsInfoDebbug"]) this.timmingsInfoDebbug = data["response"]["timmingsInfoDebbug"];
        if (data["response"]["hideColumnsTable"]) this.hideColumnsTable = data["response"]["hideColumnsTable"];
        if (data["response"]["error"] == -1) {
          this.snackBar.open("¡Vaya, parece que no has iniciado sesión!", "x", {
            duration: 3000,
            panelClass: ["red-snackbar"],
          });
          this.routingService.shouldOpenExternalWindow = false;
        } else if (data["response"]["error"] == -2 && data["response"]["idModule"] != this.footerId) {
          this.snackBar.open(
            "¡Vaya, parece que no tienes permisos para acceder a esta pantalla!",
            "x",
            {
              duration: 3000,
              panelClass: ["red-snackbar"],
            }
          );
          this.routingService.shouldOpenExternalWindow = false;
        } else if (data["response"]["error"] == -3) {
          this.snackBar.open(
            "¡Vaya, parece que este usuario no existe o se encuentra inactivo!",
            "x",
            {
              duration: 3000,
              panelClass: ["red-snackbar"],
            }
          );
          this.routingService.shouldOpenExternalWindow = false;
        } else if (data["response"]["error"] == -999) {
          let panelClass = [];
          if (this.routingService.shouldOpenExternalWindow) panelClass.push("overAllDialog");
          const dialogRef = this.dialog.open(SwapAccountComponent, {
            width: "auto",
            height: "auto",
            panelClass: panelClass,
          });

          dialogRef.afterClosed().subscribe((data) => {
            if (data.cancel) {
              if (data.reload) {
                this.router.navigate([""]).then(() => {
                  this.refreshStructure(2);
                });
              }
            } else {
              this.changeAccount(data.index);
              if (
                this.router.url ===
                "/" + this.authService.labelLanguage + "/inicio"
              ) {
                this.refreshStructure(2);
              } else {
                this.router.navigate([""]).then(() => {
                  this.refreshStructure(2);
                });
              }
            }
          });
        }

        if (moduleId == 0) moduleId = data["response"]["idModule"];
        let struct = []; if (struct['child'] !== undefined) struct = this.structure['child'];
        else struct = [this.structure];
        //if (this.structure !== undefined) this.asignFrontend(struct, moduleId);
        //this.findFormAttributesLoop(struct);
        //this.findFormAttributesInit();
        //this.checkPreviewModeGeneric();
        //this.checkDevModeGeneric();
        this.initElementInFrontend(this.structure, moduleId, this.structure);
        this.finishLoadedStructure(moduleId);
        this.authService.externalWindowStructure = this.structure;
        this.firstLoad = false;
      }
    });
  }

  fetchDSBStructure(moduleId, dsbId, idQuery, type, moduleIdParams, structure, params) {
    if (dsbId != 0) {
      let info = {
        moduleId: +moduleId,
        languageId: this.authService.languageId || this.getNavigatorLang(),
        idCompanyGeneric: this.authService.getIdCompany(true),
        idUser: this.authService.getLoggedInUserId(),
        URL: this.routingService.urlWithoutHashtag,
        idProfile: this.authService.profileIdGeneric,
        dsbType: type,
        //idCompany es el idEmpresa y el idCompanyType es si es marca(1) o empresa(2) (los id de los servicios se cogen en backend)
        dsbId: dsbId,
        lastModule: null,
        moduleIdParams: moduleIdParams
      };

      return this.endpointService.getStructure(info, this.paramControlVariables).pipe(
        map((data) => {
          if (data["response"]["checkUserIsDeveloping"] && data["response"]["checkUserIsDeveloping"] == 1) this.authService.checkUserIsDeveloping = 1;
          if (!this.devModeGenericChanged) this.devModeGeneric = this.authService.checkUserIsDeveloping == 1;
          let struct = data["response"]["structure"];
          this.formsIds = data["response"]["forms"];
          if (struct && struct.length > 0) {
            /*this.elementsArray = {
              ...this.elementsArray,
              ...data["response"]["elements"],
            };*/
            this.elementsThatAffectOthers = {
              ...this.elementsThatAffectOthers,
              ...data["response"]["elementsThatAffectOthers"],
            };
            this.affectedElements = {
              ...this.affectedElements,
              ...data["response"]["affectedElements"],
            };
            this.selectOptions = {
              ...this.selectOptions,
              ...data["response"]["results"],
            };
            this.tableData = {
              ...this.tableData,
              ...data["response"]["tableData"],
            };
            if (data["response"]["queriesInfoDebbug"]) this.queriesInfoDebbug = data["response"]["queriesInfoDebbug"];
            if (data["response"]["timmingsInfoDebbug"]) this.timmingsInfoDebbug = data["response"]["timmingsInfoDebbug"];
            if (data["response"]["hideColumnsTable"]) this.hideColumnsTable = data["response"]["hideColumnsTable"];
            //this.asignFrontend(struct, moduleId);
            //this.findFormAttributesLoop(struct);
            //this.findFormAttributesInit();
            //this.checkPreviewModeGeneric();
            //if(this.devModeGeneric) this.checkDevModeGeneric();
            this.finishLoadedStructure(moduleId);
            this.dsbStructureOpened = struct;
            structure['child'] = struct;
            if(type == 3) { // Asignamos los parámetros al usuario
              if (this.paramControlVariables[moduleIdParams]) params = this.paramControlVariables[moduleIdParams]['params'][this.paramControlVariables[moduleIdParams]['indexParam']]['intern'];
              this.assignParams(structure, params);
            } else if(type == 6) { // Asignamos los parámetros al usuario
              if (this.paramControlVariables[moduleIdParams]) params = this.paramControlVariables[moduleIdParams]['params'][this.paramControlVariables[moduleIdParams]['indexParam']]['intern'];
              this.assignParams(structure, params);
            } else { // Borramos de los parámetros del local si hay, pues sino queda mal si se abre de otro sitio
              delete this.paramControlVariables[this.structure["id_functional_parent_initial_dsb"]];
            }
          } else {
            console.error('No ha llegado ninguna structure a este Floating Element');
            this.snackBar.open(
              "¡Vaya, parece que no tienes permisos para acceder a este contenido!",
              "x",
              {
                duration: 3000,
                panelClass: ["red-snackbar"],
              }
            );
            this.dialog.closeAll();
          }

        })
      );
    }
  }

  closeFloatingElement(monitoreo, id, structure) {
    this.endpointService.updateMonitoringInformation(monitoreo, this.staticHTML).subscribe(data => {});
    let idFa = this.findElementWithId(id, false, false, true);
    if(idFa && idFa['child']) idFa['child'] = {};
    if(this.formsChanged[id]) this.formsChanged[id] = {};
    if (this.formsChanged[structure["id_functional_parent_initial_dsb"]]) this.formsChanged[structure["id_functional_parent_initial_dsb"]][this.paramControlVariables[this.currentInitialArea["key"]]['indexParam']] = new Map();
    this.emptyStructuresArrays(2);
  }

  assignParams(subStructure, params){
    if(subStructure) {
      for(let i in params) {
        if(params[i]['value']) {
          if(subStructure['id_db'] && params[i]['id_db'] && subStructure['id_db'] == params[i]['id_db'] && subStructure['bd_table'] == params[i]['bd_table'] && subStructure['bd_field'] == params[i]['bd_field']) {
            if(subStructure['multiple'] && subStructure['multiple'] == 1) {
              if(subStructure['tmp_value'] == null) subStructure['tmp_value'] = [];
              subStructure['tmp_value'].push(params[i]['value']);
              if(subStructure['form']) {
                subStructure['form'].patchValue( {
                  [subStructure['id_functional_area'] + '-' + subStructure['bd_field']]: subStructure['tmp_value']
                })
              }
            } else {
              subStructure['tmp_value'] = params[i]['value'];
              if(subStructure['form']) {
                subStructure['form'].patchValue( {
                  [subStructure['id_functional_area'] + '-' + subStructure['bd_field']]: params[i]['value']
                })
              }
              break;
            }
          }
        }
      }
      if(subStructure['child']) {
        for(let j in subStructure['child']) {
          this.assignParams(subStructure['child'][j], params)
        }
      }
    }
  }

  public async refresh() {
    this.redirectByTools = true;
    if (this.getNumChanges() > 0) {
      const status = await this.openWarningDialog(1, 1);
      if (status) {
        this.refreshStructure(0);
      }
    } else this.refreshStructure(0);
  }

  refreshStructure(type = 0) {
    this.refreshStructureFinished = true;
    this.dialog.closeAll();
    switch (type) {
      case 0:
        // Recarga generic (solo pantalla, sin header, y si hay external solo la external)
        if (!this.routingService.shouldOpenExternalWindow) {
          this.routingService.reloadPage(this.router.url.split("#", 1)[0]);
        } else {
          setTimeout(() => {
            this.fetchStructureExternalWindow(this.lastExternalWindowModuleId, true);
          }, 500);
        }
        break;
      case 1:
        // Recarga generic (pantalla y header)
        this.authService.headerStructure = null;
        this.authService.footerStructure = null;
        this.loadHeaderStructure();
        this.loadFooterStructure();
        this.routingService.reloadPage(this.router.url.split("#", 1)[0]);
        break;
      case 2:
        // Recarga todo
        window.location.reload();
        break;
      case 3:
        // Recarga generic (solo pantalla, sin header, y si hay external también la external)
        this.routingService.reloadPage(this.router.url.split("#", 1)[0]);
        break;
    }
  }

  updateResults(paramsInterns, id_functional_parent_initial) {
    let ids = Object.keys(this.selectOptions);
    let elements = this.getFAFromIds(this.getAllStructures(1), ids);
    let idsTables = Object.keys(this.tableData);
    let elementsTables = this.getFAFromIds(this.getAllStructures(1), idsTables, 2);
    let params = [];
    if (!this.staticHTML) {
      let id_pantalla = 0;
      if (this.parentStructure[0]['id_functional_parent_initial']) id_pantalla = this.parentStructure[0]['id_functional_parent_initial'];
      else id_pantalla = this.parentStructure['id_functional_parent_initial']
      if (this.paramControlVariables[id_pantalla]) params = this.paramControlVariables[id_pantalla]["params"][this.paramControlVariables[id_pantalla]['indexParam']]["input"];
    } else {
      params = paramsInterns;
    }
    this.data = this.loadData(id_functional_parent_initial);
    this.endpointService.updateResults(elements, elementsTables, this.data, params).subscribe((data) => {
      this.selectOptions = data["response"]['selections'];
      this.selectOptionsChange = !this.selectOptionsChange;
      this.selectOptionsChangeEvent.next(this.selectOptionsChange);
      this.tableData = data["response"]['tables'];
      if (data["response"]["queriesInfoDebbug"]) this.queriesInfoDebbug = data["response"]["queriesInfoDebbug"];
      for (let i in this.tableData) {
        let FA = this.findElementWithId(i, false, false, true);
        if (FA && FA['id_functional_status_general'] && FA['id_functional_type'] == 3) {
          FA['id_functional_status_general'] = FA['id_functional_status_general_statusInitUpdate'];
          FA['wasInitialized'] = false;
          FA['showSub'] = false;
        }
      }
      for (let i in this.selectOptions) {
        let FA = this.findElementWithIdQuery(i, true);
        if (FA && FA['id_functional_status_general'] && FA['id_functional_type'] == 14) {
          FA['id_functional_status_general'] = FA['id_functional_status_general_statusInitUpdate'];
          FA['showSub'] = false;
        }
      }
    });
  }

  getFAFromIds(subStructure, ids, from = 1) {
    let elements = [];
    let evaluatedId = [];
    for (let i in subStructure) {
      if (subStructure[i] !== null && subStructure[i] !== undefined) {
        if (subStructure[i]['id_functional_parent_initial_dsb'] && subStructure[i]['id_functional_parent_initial_dsb'] > 0) continue;
        if (evaluatedId[subStructure[i]["id_functional_area"]]) continue;
        if ((from == 1 && ids.includes(String(subStructure[i]["id_query"]))) || (from == 2 && ids.includes(String(subStructure[i]["id_functional_area"])))) {
          evaluatedId[subStructure[i]["id_functional_area"]] = true;
          let actElement = this.cloneVariable(subStructure[i]);
          if (from == 2) {
            actElement['selectInitial'] = this.tableData[actElement["id_functional_area"]]['select'];
            actElement['columnsInitial'] = this.tableData[actElement["id_functional_area"]]['columns'];
            actElement['pkInitial'] = this.tableData[actElement["id_functional_area"]]['pkInitial'];
            actElement['paramLabelsInitial'] = this.tableData[actElement["id_functional_area"]]['paramLabelsInitial'];
            actElement['internLabelsInitial'] = this.tableData[actElement["id_functional_area"]]['internLabelsInitial'];
            actElement['allFilters'] = this.tableData[actElement["id_functional_area"]]['allFilters'];
            actElement['tableFilters'] = this.tableData[actElement["id_functional_area"]]['tableFilters'];
            actElement['elementsColumn'] = this.tableData[actElement["id_functional_area"]]['elementsColumn'];
          }
          if (subStructure[i]['id_functional_type'] == 3 || subStructure[i]['id_functional_type'] == 14) {
            subStructure[i]['showSub'] = true;
            if (subStructure[i]['id_functional_status_general'] != 2) subStructure[i]['id_functional_status_general_statusInitUpdate'] = this.cloneVariable(subStructure[i]['id_functional_status_general']);
            subStructure[i]['id_functional_status_general'] = 2;
          }
          actElement['child'] = []; //Quitamos child porque no lo necesitamos pasar
          actElement['form'] = []; //Quitamos child porque no lo necesitamos pasar
          elements.push(actElement);
        }
      }
    }
    return elements;
  }

  refreshOriginalInputsValues(subStructure: any) {
    if (subStructure !== null) {
      for (const key in subStructure) {
        if (subStructure[key]["child"]) {
          this.refreshOriginalInputsValues(subStructure[key]["child"]);
        }
        // Comprovo el seu index
        if (
          this.originalInputsValues[
          subStructure[key]["id_functional_area"] +
          "-" +
          subStructure[key]["bd_field"]
          ] != undefined
        ) {
          // Comprovo que sigui diferent
          if (
            this.originalInputsValues[
            subStructure[key]["id_functional_area"] +
            "-" +
            subStructure[key]["bd_field"]
            ] !=
            subStructure[key]["form"]["controls"][
            subStructure[key]["id_functional_area"] +
            "-" +
            subStructure[key]["bd_field"]
            ]
          )
            this.originalInputsValues[
              subStructure[key]["id_functional_area"] +
              "-" +
              subStructure[key]["bd_field"]
            ] = this.cloneVariable(
              subStructure[key]["form"]["controls"][
              subStructure[key]["id_functional_area"] +
              "-" +
              subStructure[key]["bd_field"]
              ]
            );
        }
      }
    }
  }

  buildServiceLanguageGeneric(service: string, name: string, tree, fieldType, text) {
    //Construim un mapa per tal de accedir rapidament i de forma ordenada i concisa a les posicions que necessitem
    if (service == "authService") {
      if (this.serviceLanguageGeneric.get(service) == null) {
        //No hi ha aparegut encara cap variable del authService, el creo i l'inicialitzo
        let mapId = new Map(), list = [], obj = {};
        obj[fieldType] = text;
        list.push(obj);
        mapId.set(tree["id_functional_area"], list);
        let mapName = new Map();
        mapName.set(name, mapId);
        this.serviceLanguageGeneric.set(service, mapName);
      } else {
        //Hi ha aparegut, comprobo la paraula
        if (this.serviceLanguageGeneric.get(service).get(name) == null) {
          //No hi ha aparegut encara cap variable del amb aquest nom, la creo i l'inicialitzo
          let mapId = new Map(), list = [], obj = {};
          obj[fieldType] = text;
          list.push(obj);
          mapId.set(tree["id_functional_area"], list);
          this.serviceLanguageGeneric.get(service).set(name, mapId);
        } else {
          //Hi ha aparegut, comprobo el seu id_functional_area
          if (this.serviceLanguageGeneric.get(service).get(name).get(tree["id_functional_area"]) == null) {
            //No hi ha aparegut encara cap variable amb aquest nom, la creo i la inicialitzo
            let list = [], obj = {};
            obj[fieldType] = text;
            list.push(obj);
            this.serviceLanguageGeneric.get(service).get(name).set(tree["id_functional_area"], list);
          } else {
            //Ja ha aparegut
            let obj = {};
            obj[fieldType] = text;
            this.serviceLanguageGeneric.get(service).get(name).get(tree["id_functional_area"]).push(obj);
          }
        }
      }
    } else if (service == "chatService") {
      if (this.serviceLanguageGeneric.get(service) == null) {
        //No hi ha aparegut encara cap variable del authService, el creo i l'inicialitzo
        let mapId = new Map(), list = [], obj = {};
        obj[fieldType] = text;
        list.push(obj);
        mapId.set(tree["id_functional_area"], list);
        let mapName = new Map();
        mapName.set(name, mapId);
        this.serviceLanguageGeneric.set(service, mapName);
      } else {
        //Hi ha aparegut, comprobo la paraula
        if (this.serviceLanguageGeneric.get(service).get(name) == null) {
          //No hi ha aparegut encara cap variable del amb aquest nom, la creo i l'inicialitzo
          let mapId = new Map(), list = [], obj = {};
          obj[fieldType] = text;
          list.push(obj);
          mapId.set(tree["id_functional_area"], list);
          this.serviceLanguageGeneric.get(service).set(name, mapId);
        } else {
          //Hi ha aparegut, comprobo el seu id_functional_area
          if (this.serviceLanguageGeneric.get(service).get(name).get(tree["id_functional_area"]) == null) {
            //No hi ha aparegut encara cap variable del amb aquest nom, la creo i l'inicialitzo
            let list = [], obj = {};
            obj[fieldType] = text;
            list.push(obj);
            this.serviceLanguageGeneric.get(service).get(name).set(tree["id_functional_area"], list);
          } else {
            //Ja ha aparegut
            let obj = {};
            obj[fieldType] = text;
            this.serviceLanguageGeneric.get(service).get(name).get(tree["id_functional_area"]).push(obj);
          }
        }
      }
    }
  }

  getTextGeneric(text, tree, fieldType) {
    if (!text) return tree[fieldType];
    text.toString();
    if (!(text.includes("{{") && text.includes("}}")) || text.includes("<html>") && text.includes("</html>")) return tree[fieldType];
    const tags = text.match(/{{(.*?)}}/g).map(tag => tag.slice(2, -2));
    for (let i in tags) {
      let tagSplited = tags[i].split(".");
      if (tagSplited != undefined && tagSplited[0] != undefined && tagSplited[1] != undefined) {
        this.buildServiceLanguageGeneric(tagSplited[0], tagSplited[1], tree, fieldType, tree[fieldType + '_init']);
        text = text.replace("{{" + tags[i] + "}}", this[tagSplited[0]][tagSplited[1]]);
        //if(tree['form_field'] && tree['form_field'] == 1) this.assignValueFunctionalArea(tree, text);
      }
    }
    return text;
  }

  getServiceLanguage(tree) {
    //Tractarem el cas dels texts que tenen variables per asignar dins d'ells
    //Per realitzar aixo, MOLT IMPORTANT que els noms que arribin de la BD siguin correctes i que estiguin importats
    //amb el mateix nom, és a dir, authService.xxxxxxx / endpointService.xxxxxxxx i que aquests estiguin importats i amb el mateix nom
    if (tree['childTable'] === undefined || !tree['childTable']) {
      if (tree["description_init"] !== undefined && tree["description_init"] != null) {
        if (tree["description_init"] != "" && typeof tree["description_init"] === "string")
          tree["description"] = this.getTextGeneric(tree["description_init"], tree, "description");
      }
      if (tree["text_init"] !== undefined && tree["text_init"] != null) {
        if (tree["text_init"] != "" && typeof tree["text_init"] === "string")
          tree["text"] = this.getTextGeneric(tree["text_init"], tree, "text");
      }
      if (tree["label_init"] !== undefined && tree["label_init"] != null) {
        if (tree["label_init"] != "" && typeof tree["label_init"] === "string")
          tree["label"] = this.getTextGeneric(tree["label_init"], tree, "label");
      }
      if (tree["hint_init"] !== undefined && tree["hint_init"] != null) {
        if (tree["hint_init"] != "" && typeof tree["hint_init"] === "string")
          tree["hint"] = this.getTextGeneric(tree["hint_init"], tree, "hint");
      }
      if (tree["tooltip_init"] !== undefined && tree["tooltip_init"] != null) {
        if (tree["tooltip_init"] != "" && typeof tree["tooltip_init"] === "string")
          tree["tooltip"] = this.getTextGeneric(tree["tooltip_init"], tree, "tooltip");
      }
      if (tree["tmp_value_init"] !== undefined && tree["tmp_value_init"] != null) {
        if (tree["tmp_value_init"] != "" && typeof tree["tmp_value_init"] === "string")
          tree["tmp_value"] = this.getTextGeneric(tree["tmp_value_init"], tree, "tmp_value");
      }
      if (tree["badge_init"] !== undefined && tree["badge_init"] != null) {
        if (tree["badge_init"] != "" && typeof tree["badge_init"] === "string")
          tree["badge"] = this.getTextGeneric(tree["badge_init"], tree, "badge");
      }
      if (tree["internal_routing_init"] !== undefined && tree["internal_routing_init"] != null) {
        if (tree["internal_routing_init"] != "" && typeof tree["internal_routing_init"] === "string")
          tree["internal_routing"] = this.getTextGeneric(tree["internal_routing_init"], tree, "internal_routing");
      }
      if (tree["url_init"] !== undefined && tree["url_init"] != null) {
        if (tree["url_init"].substring(0, 1) == "{" && typeof tree["url_init"] === "string") {
          let newImage = tree["url_init"].substring(2, tree["url_init"].length - 2);
          let imageService = newImage.split(".");
          this.buildServiceLanguageGeneric(imageService[0], imageService[1], tree, "url", tree["url_init"]);
          tree["url"] = this[imageService[0]][imageService[1]];
          //URL tiene la url definitica
        }
      }
    }
    return false;
  }

  fillInputValues(subStructure: any): void {
    for (const key in subStructure) {
      if (subStructure.hasOwnProperty(key)) {
        if (subStructure[key]["form_field"] == 1) {
          for (const bdField in this.inputValues[0]) {
            if (
              bdField === subStructure[key]["bd_field"] &&
              this.inputValues[0].hasOwnProperty(bdField)
            ) {
              subStructure[key]["functional_value"] =
                this.inputValues[0][bdField];
            }
          }
        } else if (
          subStructure[key]["id_functional_type"] ===
          ID_FUNCTIONAL_TYPE_EXPANSION
        ) {
          this.fillInputValues(subStructure[key]["child"]);
        } else if (
          subStructure[key]["id_functional_type"] === ID_FUNCTIONAL_TYPE_FORM
        ) {
          this.fillInputValues(subStructure[key]["child"]);
        } else if (
          subStructure[key]["id_functional_type"] === ID_FUNCTIONAL_TYPE_TAB
        ) {
          this.fillInputValues(subStructure[key]["child"]);
        }
      }
    }
  }

  fillSelectValues(idQuery): any {
    return this.endpointService.getSelectionOptions(this.data, idQuery).pipe(
      map((data) => {
        return data["response"];
      })
    );
  }

  findFormAttributesLoop(subStructure: any): void {
    if (subStructure && subStructure !== null) {
      for (const key in subStructure) {
        this.findFormAttributesElement(subStructure[key]);
        this.findFormAttributesLoop(subStructure[key]["child"]);
      }
    }
  }

  findFormAttributesElement(structure: any): void {
    if (structure["validatedForms"] != undefined && structure["validatedForms"]) return;
    if (structure["id_functional_type"] == 11) {
      let data = {};
      data["tablesToBeUpdated"] = {};
      data["formAttributes"] = [];
      //let tables: string[] = [];
      //this.buildFormAttributes(structure, tables, data); 
      // Hacemos que en cada componente se añada al FormGroup cuando se inicializa
      let genericForm = this.formBuilder.group(data["formAttributes"]);
      this.passFormToChilds(structure, genericForm);
    }
    structure["validatedForms"] = true;
  }

  checkDevModeGeneric(): void {
    let subStructure = this.getAllStructures(1);
    for (const key in subStructure) {
      this.checkDevModeGenericElement(subStructure[key]);
    }
  }

  checkDevModeGenericElement(structure) {
    if (this.devModeGeneric) {
      if (structure["hide_pre_devModeUser"] !== undefined) structure["hide"] = this.cloneVariable(structure["hide_pre_devModeUser"]);
    } else {
      structure["hide_pre_devModeUser"] = this.cloneVariable(structure["hide"]);
      setTimeout(() => {
        structure["hide"] = this.cloneVariable(structure["hide_initial"]);
        if (structure["dev"] == 1) {
          structure["hide"] = 1;
        }
      });
    }
  }

  checkPreviewModeGeneric(): void {
    if (this.staticHTML) return;
    let subStructure = this.getAllStructures(1);
    for (const key in subStructure) {
      if (subStructure[key]["hide_initial"] == 1) continue;
      this.checkPreviewModeGenericElement(subStructure[key]);
    }
  }

  checkPreviewModeGenericElement(structure) {
    if (this.previewModeUser) {
      if (structure["form_field"] == 1 && structure["id_functional_status_general"] != 5 && (!structure["tmp_value"] || structure["tmp_value"] === undefined || structure["tmp_value"] == -1)) {
        structure["id_functional_status_pre_previewModeUser"] = this.cloneVariable(structure["id_functional_status_general"]);
        setTimeout(() => {
          structure["id_functional_status_general"] = 2;
        });
      }
    } else {
      if (structure["id_functional_status_pre_previewModeUser"] && structure["id_functional_status_pre_previewModeUser"] !== undefined) {
        structure["id_functional_status_general"] = this.cloneVariable(structure["id_functional_status_pre_previewModeUser"]);
      }
    }
  }

  initElementInFrontend(structure, key, parent, continueChild = false) {
    this.addNewElementArray(structure, key);
    if (structure["id_functional_type"] == 12 && !this.checkSelectionHasResults(structure)) return;
    if (this.dataValidityPendingToExecute[structure["id_functional_area"]]) {
      this.dataValidityPendingToExecute[structure["id_functional_area"]];
      this.setStatusToElement(structure, this.dataValidityPendingToExecute[structure["id_functional_area"]]);
      delete this.dataValidityPendingToExecute[structure["id_functional_area"]];
    }

    if(!structure['asignParamsInit']) {
      structure['asignParamsInit'] = true;
      if (parent['idFAParams'] === undefined) parent['idFAParams'] = [];
      if (parent['idFAParamsInterns'] === undefined) parent['idFAParamsInterns'] = [];
      this.asignParamsInit(structure, parent['idFAParams'], parent['idFAParamsInterns']);
    }

    if (!continueChild && !structure["initializedElement"]) {
      if (parent['asignNoFloatTopRight'] === undefined) parent['asignNoFloatTopRight'] = true;
      if (parent['isPreloadedElementFrontend'] === undefined) parent['isPreloadedElementFrontend'] = false;
      if (parent['stepperId'] === undefined) parent['stepperId'] = null;
      this.asignFrontendElement(structure, key, false, parent['isPreloadedElementFrontend'], parent['asignNoFloatTopRight'], parent['stepperId']);
      if (!this.staticHTML && this.authService.checkUserIsDeveloping == 1 && !structure["creatingMode"]) {
        this.checkDevModeGenericElement(structure);
      }
      if (!this.staticHTML) {
        this.checkPreviewModeGenericElement(structure);
      }
      structure["initializedElement"] = true;
    }
    if (!structure["initializedFormAttribute"]) {
      structure["initializedFormAttribute"] = true;
      this.findFormAttributesElement(structure);
    }
    if (!structure["initFormChangeField"] && structure["form"] && structure["form_field"] == 1) {
      structure["initFormChangeField"] = true;
      this.initFormChangeField(structure);
    }
    if (!structure["initializedElementFirstTime"] && structure['child'] && (!structure["id_functional_status_general"] || structure["id_functional_status_general"] != 2) && (continueChild || (structure['id_functional_type'] == 7 || structure['id_functional_type'] == 4 || structure['id_functional_type'] == 95 || structure['id_functional_type'] == 10 || structure['id_functional_type'] == 8 || structure['id_functional_type'] == 96))) {
      structure["initializedElementFirstTime"] = true;
      for (let i in structure['child']) {
        this.initElementInFrontend(structure['child'][i], i, structure, true);
      }
    }
  }

  buildFormAttributes(subStructure: any, bdTables: string[], data: {}): void {
    let bdFieldComponent: string;
    let bdTable: string;
    if (subStructure !== null && typeof subStructure !== "undefined") {
      if (subStructure["form_field"] == 1) {
        bdFieldComponent = subStructure["bd_field"];
        bdTable = subStructure["bd_table"];
        data["formAttributes"][subStructure["id_functional_area"] + "-" + bdFieldComponent] = "";

        if (!bdTables.includes(bdTable)) {
          bdTables.push(bdTable);
          data["tablesToBeUpdated"][bdTable] = {};
        }

        data["tablesToBeUpdated"][bdTable][bdFieldComponent] = "";
      } else if (
        subStructure["id_functional_type"] === 10 ||
        subStructure["id_functional_type"] === 16 ||
        subStructure["id_functional_type"] === 91 ||
        subStructure["id_functional_type"] === 11 ||
        subStructure["id_functional_type"] === 7
      ) {
        // 10 expansion-group, 91 box, 16 radio group
        for (const key in subStructure["child"]) {
          this.buildFormAttributes(subStructure["child"][key], bdTables, data);
        }
      }
    }
  }

  passFormToChilds(subStructure: any, genericForm: UntypedFormGroup) {
    if (subStructure !== null && subStructure !== undefined) {
      if (subStructure["form_field"] == 1) {
        subStructure["form"] = genericForm;
      } else {
        if (subStructure["id_functional_type"] === 11) {
          subStructure["form"] = genericForm;
        }
        if (subStructure["child"]) {
          for (const key in subStructure["child"]) {
            this.passFormToChilds(subStructure["child"][key], genericForm);
          }
        }
      }
    }
  }

  public findFormControl(table) {
    let data = [];
    for (let s in this.parentStructure) {
      data.push(this.findFormControls_rec(this.parentStructure[s], table));
    }

    return data;
  }

  private findFormControls_rec(current, filter) {
    if (current) {
      let data = [];
      for (let child in current["child"]) {
        if (
          current["child"][child] &&
          current["child"][child]["id_functional_area"] !=
          filter["id_functional_area"] &&
          current["child"][child]["bd_table"] == filter["bd_table"] &&
          current["child"][child]["bd_field"] == filter["bd_field"] &&
          current["child"][child]["id_db"] == filter["id_db"] &&
          current["child"][child]["form_field"] == "1"
          //current['child'][child]['window'] == filter['window']
        ) {
          if (
            current["child"][child]["form"]["controls"][
            current["child"][child]["bd_field"]
            ]["value"] != ""
          ) {
            data.push(
              current["child"][child]["form"]["controls"][
              current["child"][child]["bd_field"]
              ]["value"]
            );
          }
        }
        let data2 = this.findFormControls_rec(current["child"][child], filter);
        if (data2) data = data.concat(data2);
      }
      return data;
    }
  }

  public findSelect(table) {
    let data = [];
    for (let s in this.parentStructure) {
      let data2 = this.findFormSelect_rec(this.parentStructure[s], table);
      if (data2 != undefined || data2.length != 0) data.push(data2);
    }

    return data;
  }

  private findFormSelect_rec(current, filter) {
    if (current) {
      let data = [];
      for (let child in current["child"]) {
        if (
          current["child"][child] &&
          current["child"][child]["id_functional_area"] !=
          filter["id_functional_area"] &&
          current["child"][child]["bd_table"] == filter["bd_table"] &&
          current["child"][child]["bd_field"] == filter["bd_field"] &&
          current["child"][child]["id_db"] == filter["id_db"] &&
          current["child"][child]["form_field"] == "1"
        ) {
          data.push(current["child"][child]);
        }
        let data2 = this.findFormControls_rec(current["child"][child], filter);
        if (data2 != undefined || data2.length != 0) {
          data = data.concat(data2);
        }
      }
      return data;
    }
  }

  public findCount(current) {
    let max = 0;
    if (current) {
      for (let child in current["child"]) {
        let ext = 0;
        let max_child = this.findCount(current["child"][child]);
        let id = (current["child"][child]["id_functional_area"] + "").split(
          "."
        );
        if (id.length > 1) {
          ext = Number(id[1]);
        }
        max = Math.max(ext, max_child);
      }
      return max;
    }
  }

  public translateTree(table) {
    let data = [];
    for (let s in this.parentStructure) {
      //const max = this.findCount(this.parentStructure[s]);
      this.translateRec(this.parentStructure[s], table);
    }
  }

  private inverseTag(result, oldvalue, newvalue) {
    return result.replaceAll(oldvalue, newvalue);
  }

  public translateRec(current, filter) {
    if (current) {
      for (let key in current["child"]) {
        if (current["child"][key]["window"] == filter["window"]) {
          if (
            current["child"][key]["id_query_language"] != "" &&
            current["child"][key]["id_query_language"] != null &&
            current["child"][key]["id_query_language"] != 0
          ) {
            if (Array.isArray(filter["value"])) {
              for (let i = 0; i < length; ++i) {
                const value = filter["value"][i];
                const names = [
                  "name",
                  "description",
                  "text",
                  "label",
                  "hint",
                  "tooltip",
                  "url",
                ];
                for (let name of names) {
                  if (current["child"][key][name + "_tag"]) {
                    const invTag = this.inverseTag(
                      current["child"][key][name],
                      filter["oldvalue"],
                      value
                    );
                    current["child"][key][name] = invTag;
                  }
                }
              }
            } else {
              const value = filter["value"];
              const names = [
                "name",
                "description",
                "text",
                "label",
                "hint",
                "tooltip",
                "url",
              ];
              for (let name of names) {
                if (current["child"][key][name + "_tag"]) {
                  const invTag = this.inverseTag(
                    current["child"][key][name],
                    filter["oldvalue"],
                    value
                  );
                  current["child"][key][name] = invTag;
                }
              }
            }
          }
        }

        this.translateRec(current["child"][key], filter);
      }
    }
  }

  public resetTreeStatus(data) {
    for (let key in data) {
      this.findElementWithIdAndSetStatus(key, -1);
    }
  }

  findElementWithIdAndSetStatus(idFA, status) {
    let current = this.findElementWithId(idFA, false, false, true);
    console.log("F251 idFA: ", idFA);
    if (current && current["id_functional_area"]) {
      return this.setStatusToElement(current, status);
    } else {
      this.dataValidityPendingToExecute[idFA] = status;
      return false;
    }
  }

  setStatusToElement(current, status) {
    if (status != -1) {
      this.updateStatusFormControl(current, status);
      current["id_functional_status_general"] = Number(status);
      return true;
    } else {
      this.updateStatusFormControl(current, current["id_functional_status_initial"]);
      current["id_functional_status_general"] = current["id_functional_status_initial"];
      return false;
    }
  }

  updateStatusFormControl(structure, status) {
    if (structure["newStatus"] != status && structure["form"] && structure["id_functional_type"] != 113) {
      let form: UntypedFormGroup = structure["form"];
      if (form && form.controls && form.controls[structure["id_functional_area"] + "-" + structure["bd_field"]]) {
        let formControl = form.controls[structure["id_functional_area"] + "-" + structure["bd_field"]];
        structure["newStatus"] = status;
        if (formControl !== null) {
          formControl.clearValidators();
          if ((status == 2 || status == 3) && structure["id_functional_status_general"] == 5 && !structure["creatingMode"]) {
            formControl.updateValueAndValidity({ onlySelf: true });
            structure["original_value"] = structure["tmp_value"];
          }
          if (status == 3 || (structure["hide"] && structure["hide"] == 1)) {
            formControl.disable();
          } else {
            formControl.enable();
          }
          if (status == 5 && structure["hide"] == 0 && !structure["creatingMode"]) {
            formControl.setValidators([Validators.required]);
            //structure['tmp_value'] = structure['original_value'];
          }
        }
        if (structure["id_functional_type"] == 12 && structure["newStatus"] != 2 && structure["multiple"] != 1) this.checkValueSelection(structure);
      }
    }
  }

  checkValueSelection(structure) {
    if (!structure['tmp_value'] || this.checkValueExistOnArray(this.selectOptions[structure['id_query']], structure['tmp_value']) == 0) {
      let resultText = this.checkValueExistOnArray(this.selectOptions[structure['id_query']], structure['text'])
      if (structure['text'] && structure['text'] !== "" && resultText > 0) {
        if (resultText == 2) structure['text'] = structure['text'].toString();
        if (resultText == 3) structure['text'] = Number(structure['text']);
        structure['tmp_value'] = structure['text'];
        //this.assignValueFunctionalArea(structure, structure['text']);
      } else {
        structure['tmp_value'] = null;
      }
    }
  }

  checkValueExistOnArray(array, value) {
    for (let i in array) {
      if (array[i]['value'] == value) {
        if (typeof array[i]['value'] !== typeof value) {
          if (typeof array[i]['value'] === "string") {
            return 2;
          } else {
            return 3;
          }
        }
        return 1;
      }
    }
    return 0;
  }

  getAllStructures(from = 0, onlyActualWindow = false, acceptDialog = true) {
    let fakeArray = [];
    let mainStructure = [];
    let headerStructure = [];
    let footerStructure = [];
    let dsbStructure = [];

    if (from == 1) {
      if (onlyActualWindow) { // Sirve para que me pase solo los valores de la pantalla en la que estoy
        if (acceptDialog && this.dsbStructureArray !== undefined && this.dsbStructureArray != null && this.dsbStructureArray.length && this.dsbStructureArray.length > 0) mainStructure = this.dsbStructureArray;
        else if (this.routingService.shouldOpenExternalWindow && this.externalWindowStructureArray !== undefined && this.externalWindowStructureArray != null && this.externalWindowStructureArray.length && this.externalWindowStructureArray.length > 0) mainStructure = this.externalWindowStructureArray;
        else if (this.parentStructureArray !== undefined && this.parentStructureArray != null) mainStructure = this.parentStructureArray;
      } else {
        if (acceptDialog && this.dsbStructureArray !== undefined && this.dsbStructureArray != null && this.dsbStructureArray.length && this.dsbStructureArray.length > 0) dsbStructure = this.dsbStructureArray;

        if (this.routingService.shouldOpenExternalWindow && this.externalWindowStructureArray !== undefined && this.externalWindowStructureArray != null && this.externalWindowStructureArray.length && this.externalWindowStructureArray.length > 0) mainStructure = this.externalWindowStructureArray;
        else if (this.parentStructureArray !== undefined && this.parentStructureArray != null) mainStructure = this.parentStructureArray;
      }

      if (this.headerStructureArray === undefined || this.headerStructureArray == null) this.headerStructureArray = [];
      if (this.footerStructureArray === undefined || this.footerStructureArray == null) this.footerStructureArray = [];
      headerStructure = this.headerStructureArray;
      footerStructure = this.footerStructureArray;
    } else if (from == 2) {
      if (acceptDialog && this.dsbStructureArrayFA !== undefined && this.dsbStructureArrayFA != null && typeof this.dsbStructureArrayFA === 'object' && Object.keys(this.dsbStructureArrayFA).length > 0) dsbStructure = this.dsbStructureArrayFA;

      if (this.routingService.shouldOpenExternalWindow && this.externalWindowStructureArrayFA !== undefined && this.externalWindowStructureArrayFA != null && typeof this.externalWindowStructureArrayFA === 'object' && Object.keys(this.externalWindowStructureArrayFA).length > 0) mainStructure = this.externalWindowStructureArrayFA;
      else if (this.parentStructureArrayFA !== undefined && this.parentStructureArrayFA != null) mainStructure = this.parentStructureArrayFA;

      if (this.headerStructureArrayFA === undefined || this.headerStructureArrayFA == null) this.headerStructureArrayFA = [];
      if (this.footerStructureArrayFA === undefined || this.footerStructureArrayFA == null) this.footerStructureArrayFA = [];
      headerStructure = this.headerStructureArrayFA;
      footerStructure = this.footerStructureArrayFA;
    } else if (from == 3) {
      if (acceptDialog && this.dsbStructureArrayFields !== undefined && this.dsbStructureArrayFields != null && typeof this.dsbStructureArrayFields === 'object' && Object.keys(this.dsbStructureArrayFields).length > 0) mainStructure = this.dsbStructureArrayFields;
      else if (this.routingService.shouldOpenExternalWindow && this.externalWindowStructureArrayFields !== undefined && this.externalWindowStructureArrayFields != null && typeof this.externalWindowStructureArrayFields === 'object' && Object.keys(this.externalWindowStructureArrayFields).length > 0) mainStructure = this.externalWindowStructureArrayFields;
      else if (this.parentStructureArrayFields !== undefined && this.parentStructureArrayFields != null) mainStructure = this.parentStructureArrayFields;

      if (this.headerStructureArrayFields === undefined || this.headerStructureArrayFields == null) this.headerStructureArrayFields = [];
      if (this.footerStructureArrayFields === undefined || this.footerStructureArrayFields == null) this.footerStructureArrayFields = [];
      headerStructure = this.headerStructureArrayFields;
      footerStructure = this.footerStructureArrayFields;
    } else if (from == 4) {
      if (acceptDialog && this.dsbStructureArrayQueries !== undefined && this.dsbStructureArrayQueries != null && this.dsbStructureArrayQueries.length && this.dsbStructureArrayQueries.length > 0) dsbStructure = this.dsbStructureArrayQueries;

      if (this.routingService.shouldOpenExternalWindow && this.externalWindowStructureArrayQueries !== undefined && this.externalWindowStructureArrayQueries != null && this.externalWindowStructureArrayQueries.length && this.externalWindowStructureArrayQueries.length > 0) mainStructure = this.externalWindowStructureArrayQueries;
      else if (this.parentStructureArrayQueries !== undefined && this.parentStructureArrayQueries != null) mainStructure = this.parentStructureArrayQueries;

      if (this.headerStructureArrayQueries === undefined || this.headerStructureArrayQueries == null) this.headerStructureArrayQueries = [];
      if (this.footerStructureArrayQueries === undefined || this.footerStructureArrayQueries == null) this.footerStructureArrayQueries = [];
      headerStructure = this.headerStructureArrayQueries;
      footerStructure = this.footerStructureArrayQueries;
    } else {
      if (this.routingService.shouldOpenExternalWindow && this.routingService.externalWindowStructure !== undefined && this.routingService.externalWindowStructure != null && this.routingService.externalWindowStructure.length && this.routingService.externalWindowStructure.length > 0) mainStructure = this.routingService.externalWindowStructure;
      else if (this.parentStructure !== undefined && this.parentStructure != null) mainStructure = this.parentStructure;

      if (this.authService.headerStructure === undefined || this.authService.headerStructure == null) this.authService.headerStructure = [];
      if (this.authService.footerStructure === undefined || this.authService.footerStructure == null) this.authService.footerStructure = [];
      headerStructure = this.authService.headerStructure;
      footerStructure = this.authService.footerStructure;
    }
    if (from == 2 || from == 3 || from == 4) {
      return fakeArray = { ...dsbStructure, ...mainStructure, ...headerStructure, ...footerStructure };
    } else {
      return fakeArray.concat(dsbStructure, mainStructure, headerStructure, footerStructure);
    }
  }

  findElementWithId(idFA, findParent, findOriginal, returnOnlyChild) {
    let fake = this.getAllStructures(1);
    let newFA = this.findElementWithIdLoop(fake, idFA, "id_functional_area", findParent);
    if (!newFA || newFA === undefined || newFA === null || !newFA['child'] || newFA['child'] === undefined || newFA['child'] === null) {
      if (findOriginal) newFA = this.findElementWithIdLoop(fake, idFA, "id_functional_area_original", findParent);
      if (!newFA || newFA === undefined || newFA === null || !newFA['child'] || newFA['child'] === undefined || newFA['child'] === null) {
        newFA = this.findElementWithIdLoop(fake, idFA, "id_functional_area", findParent, 1);
        if (!newFA || newFA === undefined || newFA === null || !newFA['child'] || newFA['child'] === undefined || newFA['child'] === null) {
          if (findOriginal) newFA = this.findElementWithIdLoop(fake, idFA, "id_functional_area_original", findParent, 1);
        }
      }
    }
    if (returnOnlyChild && newFA && newFA['child']) newFA = newFA['child'];
    return newFA;
  }

  findElementWithIdQuery(idQuery, returnOnlyChild) {
    let fake = this.getAllStructures(2);
    let newFA = this.findElementWithIdLoop(fake, idQuery, 'id_query');
    if (returnOnlyChild && newFA && newFA['child']) newFA = newFA['child'];
    return newFA;
  }

  public findElementWithIdLoop(current, id, search = "id_functional_area", findParent = true, type = 0) {
    let element = null;
    let keyStrcutureChild = -1;
    let parent = null;
    if (!(current["id_functional_area"]) && current["child"]) current = current["child"];
    if (current && id != null) {
      if (type == 0 && (search == "id_functional_area" || search == "id_functional_area_original")) {
        current = this.getAllStructures(2);
        if (current[id]) {
          if (findParent) parent = this.findParent(current[id]);
          if (current[id]['keyStrcutureChild']) keyStrcutureChild = current[id]['keyStrcutureChild'];
          element = { child: current[id], parent: parent, key: keyStrcutureChild };
        }
      } else if (type == 0 && search == "id_query") {
        current = this.getAllStructures(4);
        if (current[id]) {
          if (findParent) parent = this.findParent(current[id]);
          if (current[id]['keyStrcutureChild']) keyStrcutureChild = current[id]['keyStrcutureChild'];
          element = { child: current[id], parent: parent, key: keyStrcutureChild };
        }
      } else {
        current = this.getAllStructures(1);
        for (let i in current) {
          if (current[i] && current[i][search] && current[i][search] == id) {
            if (findParent) parent = this.findParent(current[i]);
            if (current[i] && current[i]['keyStrcutureChild']) keyStrcutureChild = current[i]['keyStrcutureChild'];
            element = { child: current[i], parent: parent, key: keyStrcutureChild };
            break;
          }
        }
      }
    }
    return element;
  }

  private findParent(current) {
    let parent = null;
    if (current && current['id_functional_parent']) {
      parent = this.findElementWithId(current['id_functional_parent'], false, false, true);
      if ((!parent || parent == null || parent == undefined) && typeof current['id_functional_parent'] === 'string' && current['id_functional_parent'].indexOf(".") > 0) {
        let id_fa = current['id_functional_parent'].split(".");
        parent = this.findElementWithId(parseInt(id_fa[0]), false, false, true);
      }
    }
    return parent;
  }

  public parseStyle(struct): any {
    if (struct["style"] == 0) struct["style"] = null;
    let styles = {};
    for (let [key, value] of Object.entries(struct)) {
      if (value !== null && value != undefined && key == "style") {
        let elements = struct[key].split(";");
        let map = {};
        elements.forEach((element) => {
          if (element !== "") {
            let index = element.indexOf(":");
            let propertyName = element.slice(0, index);
            let propertyValue = element.slice(index + 1) + ";";
            map[propertyName] = propertyValue;
          }
        });
        styles[key] = map;
      }
    }
    return styles;
  }

  private shouldChangeState(newState, oldState) {
    // 5 1 3 2 4
    if (newState != oldState) {
      if (newState == 5) {
        return -1;
      }
      if (newState == 4) {
        return 1;
      }
      if (newState == 1 && oldState == 3) {
        return -1;
      }
      if (newState == 3 && oldState == 2) {
        return -1;
      }
      if (newState == 1 && oldState == 2) {
        return -1;
      }

      return 1;
    } else {
      return 0;
    }
  }

  private shouldChangeStatePermissions(newState, oldState) {
    // 2 4 3 5 1
    if (newState != oldState) {
      if (newState == 2) {
        return -1;
      }
      if (newState == 1) {
        return 1;
      }
      if (newState == 4 && oldState == 3) {
        return -1;
      }
      if (newState == 3 && oldState == 5) {
        return -1;
      }
      if (newState == 4 && oldState == 5) {
        return -1;
      }

      return 1;
    } else {
      return 0;
    }
  }

  public dataValidityMultiSelect(value, structure, NGForIndex) {
    const id_functional_area = structure["id_functional_area_original"] || structure["id_functional_area"];
    const affected_areas_ids = this.elementsThatAffectOthers[id_functional_area];
    const affected_areas = {};
    let anyToInitial = false;
    for (let i in affected_areas_ids) {
      if (Number(i) != -1) {
        if (!this.affectedElements[affected_areas_ids[i]]) {
          anyToInitial = true;
        } else {
          for (let id_func_area in this.affectedElements[affected_areas_ids[i]]) {
            if (!affected_areas[id_func_area]) affected_areas[id_func_area] = [];
            let newId = id_func_area;
            if (NGForIndex > 0) newId = this.cloneVariable(newId) + "." + NGForIndex;
            if (value.indexOf(Number(i)) >= 0) {
              const status = this.affectedElements[affected_areas_ids[i]][id_func_area];
              affected_areas[newId].push(status[0]);
            } else {
              const elem = this.findElementWithId(newId, false, false, true);
              if (elem) {
                affected_areas[newId].push(elem["id_functional_status_initial"]);
                //break;
              }
            }
          }
        }
      }
    }

    if (anyToInitial) {
      for (let i in affected_areas) {
        const elem = this.findElementWithId(i, false, false, true);
        if (elem && elem["id_functional_status_initial"]) {
          affected_areas[i].push(elem["id_functional_status_initial"]);
          break;
        }
      }
    }
    return affected_areas;
  }

  public updateStatus(value, structure, checkbox = false) {
    let permissions = -1;
    let id_functional_area = structure["id_functional_area_original"] || structure["id_functional_area"];
    if(id_functional_area) {
      id_functional_area = id_functional_area.toString().split(".")[0];
      let NGForIndex = -1;
      if (structure["ngForId"] != -1) {
        let elementContents = structure["id_functional_area"].toString().split(".");
        if (elementContents[1] > 0) NGForIndex = elementContents[1];
        //else NGForIndex = 0;
      }
      if (value || value === 0) {
        if (Array.isArray(value)) {
          const affected_areas = this.dataValidityMultiSelect(value, structure, NGForIndex);
          for (let id in affected_areas) {
            if (this.haveToContinueNGFOR(id, structure, NGForIndex)) continue;
            const affected_area_sorted = affected_areas[id].sort(this.shouldChangeState);
            const status = affected_area_sorted[0];
            let newId = id;
            if (NGForIndex > 0) newId = this.cloneVariable(id) + "." + NGForIndex;
            let elem = this.findElementWithId(newId, false, false, true);
            if(elem && elem['id_functional_parent_initial_dsb'] != undefined) {
              if(elem['id_functional_parent_initial_dsb'] != structure["id_functional_parent_initial_dsb"]) continue;  
              this.findElementWithIdAndSetStatus(newId, status);
            } else {
              this.findElementWithIdAndSetStatus(newId, status);
            }
          }
        } else if (!Array.isArray(value) && value !== undefined) {
          if (this.elementsThatAffectOthers[id_functional_area]) {
            const id_data_type = this.elementsThatAffectOthers[id_functional_area][value];
            const toRefresh = this.resetFunctionalAreas(value, structure, NGForIndex);
            this.resetTreeStatus(toRefresh);
            if (id_data_type) {
              for (let i in id_data_type) {
                let idsToChange = this.affectedElements[id_data_type[i]];
                if (idsToChange) {
                  for (let id in idsToChange) {
                    if (this.haveToContinueNGFOR(id, structure, NGForIndex)) continue;
                    if (structure["id_functional_status_general"] != 2) {
                      let status = idsToChange[id][0];
                      let newId = id;
                      if (NGForIndex > 0) newId = this.cloneVariable(id) + "." + NGForIndex;
                      let elem = this.findElementWithId(newId, false, false, true);
                      if(elem && elem['id_functional_parent_initial_dsb'] != undefined) {
                        if(elem['id_functional_parent_initial_dsb'] != structure["id_functional_parent_initial_dsb"]) continue;  
                        this.setStatusFromSingleValue(status, newId);
                      } else {
                        this.setStatusFromSingleValue(status, newId);
                      }
                    }
                  }
                } else if (checkbox) {
                  this.setStatusToInitial(structure, NGForIndex);
                }
              }
            }
          }
        } else {
          this.setStatusToDefault(structure, NGForIndex);
        }
      }
    }
  }
  

  private haveToContinueNGFOR(id, structure, NGForIndex) {
    if (structure["ngForId"] > 0) {
      let elementContentsId = id.split(".");
      if (NGForIndex > 0) elementContentsId = structure['id_functional_area'].split(".");
      if (NGForIndex != -1 && !(+elementContentsId[elementContentsId.length - 1] > 0 && +elementContentsId[elementContentsId.length - 1] == NGForIndex)) return true;
      if (NGForIndex == -1 && +elementContentsId[1] > 0) return true;
    }
    return false;
  }

  private resetFunctionalAreas(val, structure, NGForIndex) {
    const id_functional_area = structure["id_functional_area_original"] || structure["id_functional_area"];
    if (this.elementsThatAffectOthers[id_functional_area]) {
      const toRefresh = {};
      for (let e in this.elementsThatAffectOthers[id_functional_area]) {
        const ids = this.elementsThatAffectOthers[id_functional_area][e];
        for (let i in ids) {
          let x = ids[i];
          for (let id in this.affectedElements[x]) {
            if (this.haveToContinueNGFOR(id, structure, NGForIndex)) continue;
            let newId = id;
            if (NGForIndex > 0) newId = this.cloneVariable(id) + "." + NGForIndex;
            let elem = this.findElementWithId(newId, false, false, true);
            if(elem && elem['id_functional_parent_initial_dsb'] != undefined) {
              if(elem['id_functional_parent_initial_dsb'] != structure["id_functional_parent_initial_dsb"]) continue;
              if (e != val) {
                toRefresh[newId] = true;
              }
            } else if (e != val) {
              toRefresh[newId] = true;
            }
          }
        }
      }
      return toRefresh;
    }
  }

  private detectConflict(me, idToChange) {
    if (this.functional_area_changeds[idToChange]) {
      return true;
    }

    return false;
  }

  private setStatusFromSingleValue(status, id) {
    let changed = this.findElementWithIdAndSetStatus(id, status);
    if (changed) {
      // TODO JAN: DEBERÍA HACERLO
      //this.resetTreeStatus();
    }
  }

  private setStatusToInitial(structure, NGForIndex) {
    const id_functional_area = structure["id_functional_area_original"] || structure["id_functional_area"];
    if (this.elementsThatAffectOthers[id_functional_area]) {
      const firstKey = Object.keys(
        this.elementsThatAffectOthers[id_functional_area]
      )[0];
      const ids =
        this.affectedElements[
        this.elementsThatAffectOthers[id_functional_area][firstKey]
        ];
      for (let id in ids) {
        if (this.haveToContinueNGFOR(id, structure, NGForIndex)) continue;
        // Si pasas -1 como tercer parametro -> pone functional_area_status_general al estado inicial
        let conflict = this.detectConflict(id_functional_area, id);
        let newId = id;
        if (NGForIndex > 0) newId = this.cloneVariable(id) + "." + NGForIndex;
        let elem = this.findElementWithId(newId, false, false, true);
        if(elem && elem['id_functional_parent_initial_dsb'] != undefined) {
          if(elem['id_functional_parent_initial_dsb'] != structure["id_functional_parent_initial_dsb"]) continue;  
          let changed = this.findElementWithIdAndSetStatus(newId, -1);
          if (changed) {
            this.functional_area_changeds[newId] = id_functional_area;
          }
        } else {
          let changed = this.findElementWithIdAndSetStatus(newId, -1);
          if (changed) {
            this.functional_area_changeds[newId] = id_functional_area;
          }
        }
      }
    }
  }

  private setStatusToDefault(structure, NGForIndex) {
    const id_functional_area = structure["id_functional_area_original"] || structure["id_functional_area"];

    if (this.elementsThatAffectOthers[id_functional_area]) {
      const firstKey = -1;
      const ids = this.affectedElements[this.elementsThatAffectOthers[id_functional_area][firstKey]];
      for (let id in ids) {
        if (this.haveToContinueNGFOR(id, structure, NGForIndex)) continue;
        for (let s in this.parentStructure) {
          // Si pasas -1 como tercer parametro -> pone functional_area_status_general al estado iniciado
          //this.dispatcher.notify({id_functional_area:id,status:-1});
          let conflict = this.detectConflict(id_functional_area, id);
          let newId = id;
          if (NGForIndex > 0) newId = this.cloneVariable(id) + "." + NGForIndex;
          let elem = this.findElementWithId(newId, false, false, true);
          if(elem && elem['id_functional_parent_initial_dsb'] != undefined) {
            if(elem['id_functional_parent_initial_dsb'] != structure["id_functional_parent_initial_dsb"]) continue;  
            let changed = this.findElementWithIdAndSetStatus(newId, ids[id][0]);
            if (changed) {
              delete this.functional_area_changeds[newId];
            }
          } else {
            let changed = this.findElementWithIdAndSetStatus(newId, ids[id][0]);
            if (changed) {
              delete this.functional_area_changeds[newId];
            }
          }
        }
      }
    }
  }

  public getNextPage() {
    if (this.historyIndex < this.history.length) {
      this.redirectByTools = true;
      return this.history[this.historyIndex + 1];
    }
  }

  public updateHistoryIndex(n) {
    if (this.historyIndex + n >= 0) this.historyIndex = this.historyIndex + n;
  }

  public getPreviousPage() {
    if (this.historyIndex > 0) {
      this.redirectByTools = true;
      return this.history[this.historyIndex - 1];
    }
  }

  public pushPage(page) {
    let aux = page.url.split('/');
    page.url = aux[aux.length - 1];
    aux = page.url.split('#');
    page.url = aux[0];
    let removed = false;
    if (!this.redirectByTools) {
      if (this.historyIndex < this.history.length - 1 && !(this.history[this.historyIndex] && this.history[this.historyIndex].url && this.history[this.historyIndex].url == page.url)) {
        this.removePages(this.historyIndex);
        removed = true;
      }
      if (this.history.length == 0 || page.url != this.history[this.history.length - 1].url) {
        page.index = this.history.length;
        if(this.historyIndex == -1 || page.index == this.historyIndex + 1 || removed) {
          this.history.push(page);
          this.updateHistoryIndex(1);
        }
      }
    }
    this.redirectByTools = false;
  }

  public removePages(index) {
    this.history = this.history.slice(0, index + 1);
    this.historyIndex = this.history.length - 1;
  }

  public clearHistory() {
    this.history = [];
    this.historyIndex = -1;
  }

  public updateFormHistory(structure, id_dsb, parent, id_parent, id_functional_area, type, old, newValue, internal_old, internal_new, label, typeField, hasParameters, isFieldComodin) {
    let lastValueOnExecuteFunction = undefined;
    if (id_dsb > 0) parent = id_dsb;
    if (id_dsb == 0 && this.formsChanged && this.formsChanged[parent] && this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']] && this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][type + id_functional_area] && this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][type + id_functional_area]["lastValueOnExecuteFunction"] !== undefined) lastValueOnExecuteFunction = this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][type + id_functional_area]["lastValueOnExecuteFunction"];
    if (id_functional_area == "duplicated") {
      //Si es tracta de un element duplicat s'entrara aqui per poder asignar-li el canvi
      if (structure['id_functional_area'] == "4989.4") console.log("57087")
      if (this.lastNameDuplicated != undefined && hasParameters) {
        // Quan te parametres es comprova si hi ha més d'una opcio seleccionada
        this.elemsToDuplicate = this.lastNameDuplicated.split(",");
      } else {
        this.elemsToDuplicate = [typeField];
      }
      let idFunctionalArea = structure["id_functional_area"];
      for (let elem in this.elemsToDuplicate) {
        if (this.elemsToDuplicate.length > 1)
          if (!hasParameters) {
            // Es tracta d'un element sense parametres
            this.lastLabel = typeField;
            if (this.lastLabel !== null)
              this.formsChanged[structure["id_functional_parent_initial"]][this.paramControlVariables[structure["id_functional_parent_initial"]]['indexParam']][this.lastLabel.replace(" ", "-") + "_NEW_" + (structure["ngForIdIndex"])] = {
                id: this.lastManagedChanges,
                id_parent: id_parent,
                labelNewDuplicated: true,
                isDuplicated: true,
                hasNgForOptions: true,
                oldValue: old,
                newValue: newValue,
                type: type,
                is_from_duplicated: false,
                is_from_no_params: true,
                id_duplicated: structure["ngForIdIndex"],
                tooltip: this.lastLabel,
                id_functional_area_to_duplicate: idFunctionalArea,
                name:
                  this.lastLabel.replace(" ", "-") +
                  "_NEW_" +
                  structure["ngForIdIndex"],
                old: this.lastLabel,
                new: undefined,
                internal_value: { old: "NEW", new: "label" },
                numerChange: ++this.numTimesFormsChanged,
                id_functional_area: idFunctionalArea,
                lastValueOnExecuteFunction: lastValueOnExecuteFunction,
                isFieldComodin: isFieldComodin,
                idDB: structure["id_db"],
                bdTable: structure["bd_table"],
                bdField: structure["bd_field"]
              };
          } else {
            // Es tracta d'un element amb parametres
            this.formsChanged[this.lastParent][this.paramControlVariables[this.lastParent]['indexParam']][this.lastLabel + "NEW_" + (structure["ngForIdIndex"]).toString()] = {
              id: this.lastManagedChanges,
              id_parent: id_parent,
              labelNewDuplicated: true,
              isDuplicated: true,
              hasNgForOptions: true,
              oldValue: old,
              newValue: newValue,
              type: type,
              is_from_duplicated: false,
              is_from_no_params: false,
              id_duplicated: structure["ngForIdIndex"],
              id_functional_area_to_duplicate: idFunctionalArea,
              name:
                this.lastLabel +
                "NEW_" +
                (structure["ngForIdIndex"]).toString(),
              old: this.lastOld + ":",
              new: this.elemsToDuplicate[elem],
              internal_value: { old: "NEW", new: "label" },
              numerChange: ++this.numTimesFormsChanged,
              id_functional_area: idFunctionalArea,
              lastValueOnExecuteFunction: lastValueOnExecuteFunction,
              isFieldComodin: isFieldComodin,
              idDB: structure["id_db"],
              bdTable: structure["bd_table"],
              bdField: structure["bd_field"]
            };
          }
        // L'inserto en la estructura d'elements duplicated
        this.duplicatedIds[structure["id_functional_area"]] = {
          id: structure["ngForIdIndex"],
          name: this.elemsToDuplicate[elem],
          id_functional_area: idFunctionalArea,
          parent: parent,
          id_parent: id_parent,
        };
        --this.lastManagedChanges;
      }
      this.elemsToDuplicate = [];
    } else {
      if (structure['id_functional_area'] == "4989.4") console.log("57087 BBBB")
      if ((old === "" || old === null) && newValue !== "" && newValue !== null) {
        old = "-";
      }
      if (newValue === "" || newValue === null) {
        newValue = "-";
      }
      if (old !== newValue && (type == "checkbox_" || type == "slide_" || old || old === 0) && (type == "checkbox_" || type == "slide_" || newValue || newValue === 0)) {
        if (!this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']]) {
          this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']] = new Map();
        }
        if (this.duplicatedIds[id_parent] != undefined || this.duplicatedIds[id_parent] != null) {
          if (!hasParameters) {
            this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][type + id_functional_area] = {
              id: this.lastManagedChanges,
              id_parent: id_parent,
              labelNewDuplicated: false,
              isDuplicated: true,
              hasNgForOptions: true,
              oldValue: old,
              newValue: newValue,
              type: type,
              id_duplicated: this.duplicatedIds[id_parent]["id"],
              tooltip: label + ": " + this.duplicatedIds[id_parent]["name"],
              name: type + id_functional_area,
              old: typeField,
              internal_value: { old: internal_old, new: internal_new },
              numerChange: ++this.numTimesFormsChanged,
              lastValueOnExecuteFunction: lastValueOnExecuteFunction,
              isFieldComodin: isFieldComodin,
              idDB: structure["id_db"],
              bdTable: structure["bd_table"],
              bdField: structure["bd_field"]
            };
          } else {
            this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][this.paramControlVariables[parent]['indexParam']][type + id_functional_area] = {
              id: this.lastManagedChanges,
              id_parent: id_parent,
              labelNewDuplicated: false,
              isDuplicated: true,
              hasNgForOptions: true,
              oldValue: old,
              newValue: newValue,
              type: type,
              is_from_duplicated: true,
              is_from_no_params: false,
              id_duplicated: this.duplicatedIds[id_parent]["id"],
              tooltip: this.duplicatedIds[id_parent]["name"],
              name: type + id_functional_area,
              old: old,
              new: newValue,
              internal_value: { old: internal_old, new: internal_new },
              numerChange: ++this.numTimesFormsChanged,
              id_functional_area: id_functional_area,
              lastValueOnExecuteFunction: lastValueOnExecuteFunction,
              isFieldComodin: isFieldComodin,
              idDB: structure["id_db"],
              bdTable: structure["bd_table"],
              bdField: structure["bd_field"]
            };
          }
        } else {
          let idNoDuplicated;
          if (typeof id_functional_area === "string") {
            idNoDuplicated = id_functional_area.split(".");
            idNoDuplicated = parseInt(idNoDuplicated[1]) + 1;
          } else idNoDuplicated = 1;
          const element2 = this.findElementWithId(id_parent, true, false, false);
          if (element2 && element2["parent"]) {
            if ((element2["parent"]['id_table_relations_ng_for'] && element2["parent"]['id_table_relations_ng_for'] > 0) || (element2["parent"]['id_query_ng_for'] && element2["parent"]['id_query_ng_for'] > 0)) {
              this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][type + id_functional_area] = {
                id: this.lastManagedChanges,
                id_parent: id_parent,
                labelNewDuplicated: false,
                isDuplicated: false,
                hasNgForOptions: true,
                oldValue: old,
                newValue: newValue,
                type: type,
                is_from_duplicated: false,
                is_from_no_params: false,
                id_duplicated: idNoDuplicated,
                tooltip: element2["parent"]["label"],
                name: type + id_functional_area,
                old: old,
                new: newValue,
                internal_value: { old: internal_old, new: internal_new },
                numerChange: ++this.numTimesFormsChanged,
                id_functional_area: id_functional_area,
                lastValueOnExecuteFunction: lastValueOnExecuteFunction,
                isFieldComodin: isFieldComodin,
                idDB: structure["id_db"],
                bdTable: structure["bd_table"],
                bdField: structure["bd_field"]
              };
            } else if (element2["parent"]["label"] !== undefined) {
              this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][type + id_functional_area] = {
                id: this.lastManagedChanges,
                id_parent: id_parent,
                labelNewDuplicated: false,
                isDuplicated: false,
                hasNgForOptions: false,
                oldValue: old,
                newValue: newValue,
                type: type,
                is_from_duplicated: false,
                is_from_no_params: false,
                id_duplicated: idNoDuplicated,
                tooltip: element2["parent"]["label"],
                name: type + id_functional_area,
                old: old,
                new: newValue,
                internal_value: { old: internal_old, new: internal_new },
                numerChange: ++this.numTimesFormsChanged,
                id_functional_area: id_functional_area,
                lastValueOnExecuteFunction: lastValueOnExecuteFunction,
                isFieldComodin: isFieldComodin,
                idDB: structure["id_db"],
                bdTable: structure["bd_table"],
                bdField: structure["bd_field"]
              };
            }
          }
        }
        --this.lastManagedChanges;
      }
    }
  }

  public updateValueCheckBox(structure, value) {
    structure["invalid"] = false;
    if (value) structure["tmp_value"] = 1;
    else {
      if (structure["id_functional_status_general"] === 5)
        structure["invalid"] = true;
      structure["tmp_value"] = 0;
    }

    const id = value ? 1 : 2;
    this.updateStatus(id, structure, true);
  }

  public deleteFormHistory(parent, key) {
    if (
      this.formsChanged[parent] && this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']] &&
      this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][key] != undefined
    ) {
      delete this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][key];
    }
  }

  public async revertDuplicateFromValue(parent, input, structure = null) {
    // Revert de duplicar un element
    // 1. Poner id_functional_status = 2
    // 2. Delete aparicions en el historial de l'element duplicat i els seus fills

    // Si la structure no es null se trata la structure
    let elem;
    if (structure != null) elem = this.findElementWithId(structure["child"][0]["id_functional_area"], true, false, false);
    else elem = this.findElementWithId(input["value"]["id_functional_area_to_duplicate"], true, false, false);
    if (elem) {
      const status = await this.openWarningDialog(2, 0);
      if (status) {
        if (structure != null) structure["id_functional_status_general"] = 2;
        else elem["child"]["id_functional_status_general"] = 2;
        this.deleteChildsFromHistory(
          parent,
          elem["child"]["id_functional_area"],
          elem["parent"]["id_functional_area"]
        );
        this.refreshDuplicatedIds(
          parent,
          elem["child"]["id_functional_area"],
          elem["parent"]["id_functional_area"],
          elem["child"]
        );
      }
    }
  }

  // Quan dupliquem un element i fem un revert hem de borrar de l'historial els canvis dels seus fills (si hi ha)
  public deleteChildsFromHistory(parent, key, id_parent) {
    let duplicatedId;
    for (let i in this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']]) {
      if (this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][i]["id_parent"] == key) {
        //Netejo els seus fills
        delete this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][i];
      } else if (
        this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][i]["id_functional_area_to_duplicate"] == key
      ) {
        //Netejo el propi element duplicate
        duplicatedId = this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][i]["id_duplicated"];
        delete this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][i];
      }
    }

    for (let elem in this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']]) {
      let parentTree = this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][elem]["id_parent"];
      if (parentTree != undefined) {
        // Es tracta d'un form d'un element duplicat
        for (let elem2 in this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']]) {
          if (
            this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][elem2][
            "id_functional_area_to_duplicate"
            ] == this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][elem]["id_parent"]
          )
            parentTree = this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][elem2]["id_parent"];
        }
      }
      if (
        this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][elem]["id_duplicated"] != null &&
        parentTree["id_functional_area"] == id_parent &&
        this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][elem]["id_duplicated"] >= duplicatedId
      ) {
        --this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][elem]["id_duplicated"];
      }
    }
  }

  // Quan dupliquem un element i fem revert hem d'actualitzar el diccionari d'elements duplicar i els valors de tots els altres elements
  public refreshDuplicatedIds(parent, key, id_parent, value) {
    let correctPosition = false;
    for (let elem in this.duplicatedIds) {
      // Comprovo si son de la mateixa pantalla
      if (this.duplicatedIds[elem]["parent"] == parent) {
        // Disminueixo en 1 el valor del seu id
        if (
          this.duplicatedIds[elem]["id_parent"] == id_parent &&
          correctPosition
        )
          --this.duplicatedIds[elem]["id"];

        // Elimino l'element que correspongui
        if (this.duplicatedIds[elem]["id_functional_area"] == key) {
          delete this.duplicatedIds[elem];
          correctPosition = true;
        }
      }
    }
  }

  public getUserInfo(callFrom = 0, idCompany = -1) {
    let hasActive = false;
    let localActive = localStorage.getItem("_in_");

    this.endpointService.getUserInfo(this.authService.userId).subscribe((data) => {
      if (data["response"]["userInfo"].length > 0) {
        this.authService.accountsGeneric = data["response"]["userInfo"];
        this.addOldParamsBackend();
        for (let i = 0; i < data["response"]["userInfo"].length; i++) {
          let isCall2Company = (idCompany == -1 || (idCompany != data["response"]["userInfo"][i]["id_empresa"] && idCompany != data["response"]["userInfo"][i]["idEmpresa"]));
          if (callFrom == 2 && isCall2Company && this.authService.accountsGeneric.length > 1) {
            this.authService.accountsGeneric[i].active = false;
            this.authService.accountsGeneric[i].value = true;
          } else if (callFrom != 2 && (i != +localActive || localActive === null) && this.authService.accountsGeneric.length > 1) {
            this.authService.accountsGeneric[i].active = false;
            this.authService.accountsGeneric[i].value = true;
          } else {
            if (callFrom == 1 && this.authService.accountsGeneric.length > 1) {
              // Si entras desde el login y hay varias cuentas, aseguramos que no haya ninguna cuenta activa, pues no debería
              this.authService.accountsGeneric[i].active = false;
              this.authService.accountsGeneric[i].value = true;
            } else {
              this.authService.accountsGeneric[i].active = true;
              this.authService.accountsGeneric[i].value = true;
              hasActive = true;

              localStorage.setItem("_in_", i.toString());
              this.getCompanyColors(data["response"]["userInfo"][i]);
              this.authService.userFullName =
                data["response"]["userInfo"][i].nombre_user;
              this.authService.profileId =
                data["response"]["userInfo"][i].perfil;
              this.authService.isMovinUser =
                data["response"]["userInfo"][i].es_usuario_movin;
              this.authService.isResponsible =
                data["response"]["userInfo"][i].es_responsable;
                /*if(!this.staticHTML) { // Ya carga de la url y lo trae retrieve
                  this.authService.languageId =
                    data["response"]["userInfo"][i].id_idioma;
                  localStorage.setItem(
                    "_ul_",
                    this.authService.languageId.toString()
                  );
                }*/
              this.authService.fechaInicioCal =
                data["response"]["userInfo"][i].hora_inicio_cal;
              this.authService.fechaFinCal =
                data["response"]["userInfo"][i].hora_fin_cal;
              this.authService.idAviso1 =
                data["response"]["userInfo"][i].id_aviso1;
              this.authService.idAviso2 =
                data["response"]["userInfo"][i].id_aviso2;
              this.authService.idAviso3 =
                data["response"]["userInfo"][i].id_aviso3;
              this.authService.espera =
                data["response"]["userInfo"][i].espera;
              this.authService.cargo =
                data["response"]["userInfo"][i].id_cargo;
              this.authService.inmoTipoPerfil =
                data["response"]["userInfo"][i].tipo_perfil;
              this.authService.responsable_community =
                data["response"]["userInfo"][i].responsable_community;
              this.authService.puede_colaborar =
                data["response"]["userInfo"][i].puede_colaborar;
              this.authService.profilePic = data["response"].foto;
              this.authService.modifica_actividades_otros =
                data["response"]["userInfo"][i].modifica_actividades_otros;

              /* CAL REVISAR */
              this.authService.inmobiliariaName =
                data["response"]["userInfo"][i].nombreInmobiliaria;
              this.authService.inmoURL =
                data["response"]["userInfo"][i].url_canonica;
              this.authService.rango_colabo =
                data["response"]["userInfo"][i].rango_colabo;
              this.authService.user_token =
                data["response"]["userInfo"][i].usuario_token;
              this.authService.dia_inicio_calendario =
                data["response"]["userInfo"][i].dia_inicio_calendario;
              this.authService.googleToken =
                data["response"]["userInfo"][i].token_google;
              this.authService.microsoftToken =
                data["response"]["userInfo"][i].token_microsoft;
              this.authService.groupId = null;
              this.authService.inmoId = null;

              if (data["response"]["userInfo"][i].id > 0) {
                // TODO JOAN: NO SE PERQUE ES FA SERVIR
                this.authService.accounts.push({
                  id: data["response"]["userInfo"][i].id,
                  idCompany: data["response"]["userInfo"][i].id_empresa,
                  name: data["response"]["userInfo"][i].nombre,
                  type: data["response"]["userInfo"][i].tipoNombre, // STRING: Marca o Empresa
                  type_account: data["response"]["userInfo"][i].type_account, // INT: 1 (Marca) o 2 (Empresa)
                  inmo_id: data["response"]["userInfo"][i].idInmobiliaria,
                  group_id: data["response"]["userInfo"][i].id_grupo,
                  group_type:
                    data["response"]["userInfo"][i].id_tipo_grupo_inmo,
                  group_name: data["response"]["userInfo"][i].nombreGrupo,
                  group_type_perfil:
                    data["response"]["userInfo"][i].tipo_perfil,
                  group_url: data["response"]["userInfo"][i].url_canonica,
                });
              }

              if (data["response"]["userInfo"][i].idInmobiliaria > 0) {
                this.authService.accounts.push({
                  id: data["response"]["userInfo"][i].idInmobiliaria,
                  name: data["response"]["userInfo"][i].nombreInmobiliaria,
                  type: "inmo",
                  type_account: 1,
                });
                this.authService.isInmobiliari = 1;
                this.authService.inmoId =
                  data["response"]["userInfo"][i].idInmobiliaria;
                this.authService.isOrphan = false;
              } else {
                this.authService.isInmobiliari = 0;
                this.authService.isOrphan = true;
                if (data["response"]["userInfo"][i].id_grupo > 0) {
                  this.authService.accounts.push({
                    id: data["response"]["userInfo"][i].id_grupo,
                    name: data["response"]["userInfo"][i].nombreGrupo,
                    type: "group",
                    type_account: 2,
                    type_group:
                      data["response"]["userInfo"][i].id_tipo_grupo_inmo,
                    tipo_perfil: data["response"]["userInfo"][i].tipo_perfil,
                    url_canonica:
                      data["response"]["userInfo"][i].url_canonica,
                  });
                  this.authService.groupId =
                    data["response"]["userInfo"][i].id_grupo;
                }
              }
            }
          }
        }

        switch (callFrom) {
          case 0: // appComponent al hacer init
            if (this.authService.accountsGeneric.length > 1) {
              // Usuari amb varis comptes
              if (hasActive) {
                this.changeAccount(localActive, true);
                this.initFreshChat();
              } else {
                this.openAccountMode(callFrom);
              }
            } else {
              // Usuari amb un sol compte o sense cap cpmpte (orfe)
              this.changeAccount(0, true);
              this.initFreshChat();
              // Joan  this.chatService.initIoConnection();
            }
            break;
          case 1: // login
            // Joan: Aquestes 2 linies estaven a loginComponent despres de cridar a aquesta funcio. Pero no se si calen.
            //this.authService.setExpirationDate();
            //this.authService.loadAdminArea();
            if (this.authService.accountsGeneric.length > 1) {
              // Usuari amb varis comptes
              this.openAccountMode(callFrom);
            } else {
              // Usuari amb un sol compte o sense cap cpmpte (orfe)
              this.changeAccount(0);
              // Joan  this.chatService.initIoConnection();
              //this.authService.login();
              if (
                this.routingService.redirectionURL !==
                "/" + this.authService.labelLanguage + "/login" &&
                this.routingService.redirectionURL !== "/" &&
                this.routingService.redirectionURL !== null
              ) {
                this.navigateToOldRoute();
                this.authService.finishToLogued = true;
              } else {
                this.refreshStructure(2);
              }
            }
            break;
          case 2: // session static
            this.changeAccount(localStorage.getItem("_in_"));
            this.authService.finishToLogued = true;
            break;
        }
      } else {
        this.snackBar.open(
          "¡Vaya, parece que este usuario no existe o ya no está activo!",
          "x",
          {
            duration: 3000,
            panelClass: ["red-snackbar"],
          }
        );
        //this.authService.logout();
      }
    });
  }

  public initFreshChat() {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    if (mediaQuery.matches) {
      if (this.authService.isUserSessionActive) {
        let name = this.authService.userFullName;
        if (this.authService.companyGenericName) name = name + " (" + this.authService.companyGenericName + ")";
        this.chat
          .init({
            token: "7a842221-3b23-4eae-b57d-37a0de87765b",
            host: "https://wchat.eu.freshchat.com",
            externalId: String(this.authService.userId), // user’s id unique to your system
            firstName: name, // user’s first name
            //lastName: "Doe",                // user’s last name
            //email: "john.doe@gmail.com",    // user’s email address
            //phone: "8668323090",            // phone number without country code
            //phoneCountryCode: "+1"          // phone’s country code
          })
          .subscribe();
      } else {
        this.chat
          .init({
            token: "7a842221-3b23-4eae-b57d-37a0de87765b",
            host: "https://wchat.eu.freshchat.com",
          })
          .subscribe();
      }
    }
  }

  public loadHeaderStructure() {
    // Start menu
    let idModuleHeader = this.headerNoLoguedId;
    if (this.authService.isUserSessionActive()) {
      idModuleHeader = this.headerId;
    }
    if (this.authService.headerStructure === null) {
      //this.fetchStructure(this.headerId, this.languageId).subscribe((data): void => {
      this.fetchStructure(idModuleHeader).subscribe((data): void => {
        this.authService.headerStructure = data;
        this.authService.loadingInit = true;
      });
    } else {
      this.authService.loadingInit = true;
    }
  }

  public loadFooterStructure() {
    // Start menu
    let idModuleFooter = this.footerNoLoguedId;
    if (this.authService.isUserSessionActive()) idModuleFooter = this.footerId;
    if (this.authService.footerStructure === null) {
      //this.fetchStructure(this.footerId, this.languageId).subscribe((data): void => {
      this.fetchStructure(idModuleFooter).subscribe((data): void => {
        this.authService.footerStructure = data;
      });
    }
  }

  public navigateToOldRoute() {
    if (this.routingService.redirectionURL !== null) {
      this.router.navigate([this.routingService.redirectionURL]);
      if (this.routingService.redirectionJoinGroup) {
        this.routingService.redirectionJoinGroup = false;
      }
    } else {
      this.router.navigate([""]);
      //this.refreshStructure(2);
    }
  }

  public openAccountMode(callFrom = 0) {
    let panelClass = [];
    if (this.routingService.shouldOpenExternalWindow) panelClass.push("overAllDialog");
    const dialogRef = this.dialog.open(SwapAccountComponent, {
      width: "auto",
      height: "auto",
      panelClass: panelClass,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.cancel) {
        if (callFrom == 1) this.authService.logout();
      } else {
        this.changeAccount(data.index);
        if (callFrom == 1) {
          if (
            this.routingService.redirectionURL !==
            "/" + this.authService.labelLanguage + "/login" &&
            this.routingService.redirectionURL !== "/login" &&
            this.routingService.redirectionURL !== "/" &&
            this.routingService.redirectionURL !== null
          ) {
            this.navigateToOldRoute();
            this.authService.finishToLogued = true;
          } else {
            this.refreshStructure(2);
          }
        } else {
          this.refreshStructure(2);
          //this.login();
        }
      }
    });
  }

  public changeAccount(i, loadHeader = false, setLocal = true) {
    if (this.authService.accountsGeneric.length > 0) {
      this.authService.activeTypeAccount = this.authService.accountsGeneric[i].type_account;
      this.authService.empresaId = this.authService.accountsGeneric[i].id;
      this.authService.empresaIdCategoryGeneric = this.authService.accountsGeneric[i].categoryCompanyId;
      this.authService.empresaIdCompanyType = this.authService.accountsGeneric[i].companyType;
      this.authService.empresaCategoryGeneric = this.authService.accountsGeneric[i].categoryCompanyName;

      //this.inmoId = this.accountsGeneric[i].inmo_id;
      //this.groupId = this.accountsGeneric[i].group_id;
      this.authService.groupType = this.authService.accountsGeneric[i].group_type;
      this.authService.groupName = this.authService.accountsGeneric[i].group_name;
      this.authService.groupTipoPerfil = this.authService.accountsGeneric[i].group_type_perfil;
      this.authService.groupURL = this.authService.accountsGeneric[i].group_url;
      this.authService.companyGenericName = this.authService.accountsGeneric[i].nombre;
      this.authService.profileIdGeneric = this.authService.accountsGeneric[i].idProfile;
      this.authService.loadAdminArea(); // TODO JOAN: Crec que no cal
      this.getCompanyColors(this.authService.accountsGeneric[i]);
      if (setLocal) {
        localStorage.setItem("_in_", i.toString());
      }
      for (let j = 0; j < this.authService.accountsGeneric.length; j++) {
        this.authService.accountsGeneric[j].active = j == i;
      }
    }
    if (loadHeader) {
      this.loadHeaderStructure();
      this.loadFooterStructure();
    }
  }

  public getCompanyColors(obj = null) {
    let colorP = this.authService.primary_color;
    if (obj && obj.primary_color) {
      colorP = obj.primary_color;
      this.authService.primary_color = obj.primary_color;
    }
    let colorS = this.authService.secondary_color;
    if (obj && obj.secondary_color) {
      colorS = obj.secondary_color;
      this.authService.secondary_color = obj.secondary_color;
    }
    let colorT = this.authService.tertiary_color;
    if (obj && obj.tertiary_color) {
      colorT = obj.tertiary_color;
      this.authService.tertiary_color = obj.tertiary_color;
    }
    let colorQ = this.authService.quaternary_color;
    if (obj && obj.quaternary_color) {
      colorQ = obj.quaternary_color;
      this.authService.quaternary_color = obj.quaternary_color;
    }
    const root = document.documentElement;
    root.style.setProperty('--primary-color', this.hexToRgb(colorP));
    root.style.setProperty('--secondary-color', this.hexToRgb(colorS));
    root.style.setProperty('--tertiary-color', this.hexToRgb(colorT));
    root.style.setProperty('--quaternary-color', this.hexToRgb(colorQ));
  }

  private hexToRgb(hex: string) {
  hex = hex.replace("#", "");
  if (hex.length !== 3 && hex.length !== 6) {
    return null; // Código hexadecimal inválido
  }
  if (hex.length === 3) {
    hex = hex.split("").map(char => char + char).join("");
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return r + ', ' + g + ', ' + b;
}

  public async openWarningDialog(messageId, titleId, fa = null) {
    switch (titleId) {
      case 1:
        this.warningTitle = "Parece que hay cambios sin guardar.";
        this.warningButton1 = "Cancelar";
        this.warningButton2 = "Continuar sin guardar";
        break;
      case 2:
        this.warningTitle = fa["hint"];
        if (!fa["hint"]) this.warningTitle = fa["label"];
        this.warningButton1 = "Cancelar";
        this.warningButton2 = "Eliminar";
        break;
      case 3:
        this.warningTitle = 'Eliminar reserva';
        this.warningButton1 = "Cancelar";
        this.warningButton2 = "Eliminar";
        break;
      default:
        this.warningTitle = "";
        this.warningButton1 = "Volver";
        this.warningButton2 = "Continuar";
        break;
    }
    switch (messageId) {
      case 1:
        this.warningMessage = "¿Quieres continuar con esta acción?";
        break;
      case 2:
        this.warningMessage =
          "¿Seguro que quiere revertir los cambios de este campo?";
        break;
      case 3:
        this.warningMessage =
          '¿Estás seguro que quieres eliminar "' + fa["label"] + '"?';
        break;
      case 4:
        if (fa && fa['description'] != null) {
          this.warningMessage = '¿Estás seguro que quieres eliminar ' + fa['description'] + '?';
        }
        else this.warningMessage = '¿Estás seguro que quieres eliminar este elemento?';
        break;
      default:
        this.warningMessage =
          "¿Estás seguro que quieres continuar con esta acción?";
        break;
    }

    let panelClass = ["dialog-material-generic-no-close-out"];
    if (this.routingService.shouldOpenExternalWindow) panelClass.push("overAllDialog");
    const dialogRef = this.dialog.open(this.warningDialog, {
      width: "250px",
      hasBackdrop: false,
      panelClass: panelClass,
    });

    return await dialogRef.afterClosed().toPromise();
  }

  highlightHeaderComponents() {
    this.highlightHeaderElements = [];
    let breadurls = this.breadcrumbs.map(function (value, index) {
      return value["url"];
    });
    this.findIfRouting(this.authService.headerStructure[0], breadurls);
  }

  findIfRouting(subStructure: any, breadurls: any[]) {
    let result = false;
    if (subStructure !== null && subStructure !== undefined) {
      if (
        (subStructure["id_function"] == 1 ||
          subStructure["id_function"] == 6) &&
        breadurls.includes(subStructure["internal_routing"])
      ) {
        this.highlightHeaderElements.push(subStructure["id_functional_area"]);
        if (subStructure["child"]) {
          for (let p in subStructure["child"])
            if (!result)
              result = this.findIfRouting(subStructure["child"][p], breadurls);
        }
        result = true;
      } else {
        if (subStructure["child"])
          for (let p in subStructure["child"])
            if (!result)
              result = this.findIfRouting(subStructure["child"][p], breadurls);
      }
      if (result && subStructure["id_functional_area"] !== undefined)
        this.highlightHeaderElements.push(subStructure["id_functional_area"]);
    }
    return result;
  }

  sendParams(idTable, idRow, param) {
    if (idTable == null && idRow == null) {
      let obj = { isTable: false, data: param };
      return obj;
    } else if (this.tableData[idTable]) {
      this.tablesActualRows.push({ "id_functional_area": idTable, "rowID": idRow });
      let obj = { isTable: true, data: this.tableData[idTable]['data'][idRow], idTable: idTable, idRow: idRow }
      return obj;
    } else {
      return null;
    }
  }

  public checkFields(element) {
    return element && element['id_db'] && element['bd_table'] && element['bd_field'] && element['id_db'] > 0 && element['bd_table'] != '' && element['bd_field'] != '' && element['id_db'] !== undefined && element['bd_table'] !== undefined && element['bd_field'] !== undefined && element['id_db'] !== null && element['bd_table'] !== null && element['bd_field'] !== null;
  }

  public asignFrontend(subStructure: any, moduleId, asignNoFloatTopRight = true, arrayParams = [], arrayParamsInterns = [], stepperId = null, isPreloadedElementChild = false): void {
    if (subStructure !== null) {
      for (const key in subStructure) {
        this.asignParamsInit(subStructure[key], arrayParams, arrayParamsInterns);
        this.asignFrontendElement(subStructure[key], key, false, isPreloadedElementChild, asignNoFloatTopRight, stepperId);
        if (subStructure[key]["child"]) {
          this.asignFrontend(subStructure[key]["child"], moduleId, subStructure[key]['asignNoFloatTopRight'], subStructure[key]['idFAParams'], subStructure[key]['idFAParamsInterns'], subStructure[key]['stepperId'], subStructure[key]['isPreloadedElementFrontend']);
        }
      }
    }
  }

  finishLoadedStructure(moduleId) {
    if (moduleId == this.headerId || moduleId == this.headerNoLoguedId) {
      this.finishedLoadStructureHeader = true;
    } else if (moduleId == this.footerId || moduleId == this.footerNoLoguedId) {
      this.finishedLoadStructureFooter = true;
    } else {
      this.finishedLoadStructure = true;
    }
  }

  private asignParamsInit(structure, arrayParams, arrayParamsInterns) {
    let InitArrayParams = this.cloneVariable(arrayParams);
    let InitArrayParamsInterns = this.cloneVariable(arrayParamsInterns);
    if (this.checkFields(structure)) {
      if (structure['param'] && structure['param'] == 1) arrayParams.push(structure['id_functional_area']);
      if (structure['param_intern'] && structure['param_intern'] == 1) arrayParamsInterns.push(structure['id_functional_area']);
    }
    if ((structure['id_table_relations_ng_for'] && structure['id_table_relations_ng_for'] > 0) || (structure['id_query_ng_for'] && structure['id_query_ng_for'] > 0)) {
      arrayParams = this.cloneVariable(InitArrayParams);
      arrayParamsInterns = this.cloneVariable(InitArrayParamsInterns);
    }
    structure['idFAParams'] = arrayParams;
    structure['idFAParamsInterns'] = arrayParamsInterns;
  }

  public asignFrontendElement(structure: any, key, isPreloadedElement, isPreloadedElementChild, asignNoFloatTopRight, stepperId): void {
    if (structure['validatedFrontend'] !== undefined && structure['validatedFrontend'] && !isPreloadedElementChild) return;
    let idPreloaded = 0;
    structure['isPreloadedElementFrontend'] = isPreloadedElement;
    if (structure['is_preloaded_element'] && structure['is_preloaded_element'] == 1) {
      if (this.preLoadedElementsGeneric[structure['id_functional_area']]) {
        structure = this.cloneVariable(this.preLoadedElementsGeneric[structure['id_functional_area']]);
        structure['isPreloadedElementFrontend'] = true;
        idPreloaded = structure['id_functional_area'];
      } else {
        this.preLoadedElementsGeneric[structure['id_functional_area']] = structure;
      }
    }


    structure['addedNewElementArray'] = false;
    structure['lastValueOnExecuteFunction'] = undefined;

    if (!isPreloadedElementChild && idPreloaded != structure['id_functional_area']) {
      if ((structure['bd_table'] && structure['bd_table'].includes('comodin')) || (structure['bd_field'] && structure['bd_field'].includes('comodin'))) structure['isFieldComodin'] = 1;

      if (structure['hide_initial'] != 1 && structure['type'] == "column" && this.hideColumnsTable[structure['id_functional_area']] !== undefined) {
        structure['id_functional_status_general'] = 2;
      }

      if (asignNoFloatTopRight && structure["id_functional_parent_initial"] != this.headerId && structure["id_functional_parent_initial"] != this.headerNoLoguedId && structure["id_functional_parent_initial"] != this.footerId && structure["id_functional_parent_initial"] != this.footerNoLoguedId &&
        structure["class_custom"] && structure["class_custom"] !== null && structure["class_custom"] != undefined && structure["class_custom"] !== ""
      ) {
        if (structure["class_custom"].includes("float-top-right")) structure['asignNoFloatTopRight'] = false;
        else if (!structure["class_custom"].includes("ignore-float-top-right")
        ) structure["class_custom"] = structure["class_custom"] + " no-float-top-right";
      }

      if (structure["id_functional_type"] == 95) {
        stepperId = structure["id_functional_area"];
      }
      if (stepperId) {
        structure["stepperId"] = stepperId;
      }

      if (this.readMode && structure["form_field"] == 1 && structure["id_functional_status_general"] !== 2) structure["id_functional_status_general"] = 3;
      if (structure["id_functional_status_general"] == undefined || structure["id_functional_status_general"] === null) structure["id_functional_status_general"] = 1;
      if (structure["id_condition"] == undefined || structure["id_condition"] == 0) structure["id_condition"] = null;
      if (structure["id_condition_badge"] == undefined || structure["id_condition_badge"] == 0) structure["id_condition_badge"] = null;
      if (structure["multiple"] === null) structure["multiple"] = 0;

      if(!structure['creatingMode']) { // Cuando no es añadir un elemento en el creator
        if (structure["id_functional_type"] == 101) {
          if (structure["type"] == "img" || structure["type"] == "iframe") {
            if (!structure["url"] || structure["url"] == "") structure["id_functional_status_general"] = 2;
          } else {
            if (!structure["text"] || structure["text"] == "") structure["id_functional_status_general"] = 2;
          }
        }
        if (structure["id_functional_type"] == 113 || structure["id_functional_type"] == 5) {
          if (!structure["class_custom"] || structure["class_custom"] === null || structure["class_custom"] == undefined || structure["class_custom"] === "") {
            if (structure["label"] && structure["label"] !== null && structure["label"] != undefined && structure["label"] !== "") {
              structure["class_custom"] = "generic-buttons-black";
            } else if (structure["class"] && structure["class"] !== null && structure["class"] != undefined && structure["class"] !== "") {
              structure["class"] = "only-icon";
            }
          }
        }
  
        if (structure["id_functional_type"] == 5 && structure["icon"] == "delete" && structure["label"] && structure["label"] !== null && structure["label"] != undefined && structure["label"] !== "") {
          structure["class_custom"] = structure["class_custom"] + " generic-buttons-red";
        }
      }

      if (!structure["class_custom"] || structure["class_custom"] === null || structure["class_custom"] == undefined || structure["class_custom"] === "") {
        if (structure["label"] && structure["label"] !== null && structure["label"] != undefined && structure["label"] !== "") {
          structure["class_custom"] = structure["class_custom"] + " generic-element-" + structure["id_functional_area"];
        } else {
          structure["class_custom"] = "generic-element-" + structure["id_functional_area"];
        }
      }

      if (structure['id_functional_type'] == 3 && structure['child']) {
        for (let i in structure['child']) {
          if (structure['child'][i]['id_functional_type'] == 9 && structure['child'][i]['type'] == "expansion") {
            structure["class_custom"] = structure["class_custom"] + ' is-table-with-expansion ';
            break;
          }
        }
      }

      if (structure['id_functional_type'] == 106 && (this.lastDeferContainer == -1 || this.lastDeferContainer == structure['id_functional_area'])) {
        this.lastDeferContainer = structure['id_functional_area'];
        structure['id_functional_status_general'] = 1;
        console.log("DEFEEEEER", this.lastDeferContainer, structure['id_functional_area'], structure['id_functional_status_general'], structure);
      }

      this.assignClass(structure, +key);

      structure['width_general'] = structure['width_general'] != 0 ? structure['width_general'] : 100;
      structure['styleParsed'] = this.parseStyle(structure);
      this.getServiceLanguage(structure);

      if (structure['icon']) {
        if (structure['icon_type'] == 'Outlined') {
          structure['icon_type'] = "material-icons-outlined";
        } else if (structure['icon_type'] == 'Filled') {
          structure['icon_type'] = "material-icons";
        } else if (structure['icon_type'] == 'Round') {
          structure['icon_type'] = "material-icons-round";
        } else if (structure['icon_type'] == 'Two-tone') {
          structure['icon_type'] = "material-icons-two-tone";
        } else if (structure['icon_type'] == 'Sharp') {
          structure['icon_type'] = "material-icons-sharp";
        }

        structure['icon_type'] = structure['icon_type'] + " generic-icon-" + structure['icon'];
      }

      if (structure['id_functional_type'] == 5 && !structure['creatingMode']) {
        if (structure['class'] && structure['class'] != null && structure['class'] != 0 && structure['class'] && structure['class'] != "only-icon") {
          structure['class'] = structure['class'] + " button-generic"
        } else {
          structure['class'] = "no-angular-class button-generic"
        }
        if (structure['url'] == undefined || structure['url'] == "0" || structure['url'] == "") structure['url'] = null;
      }

      if (structure['id_functional_type'] == 118) {
        structure['text'] = structure['text'].replace(new RegExp('"', 'g'), "'");
        structure['text'] = this.sanitizer.bypassSecurityTrustHtml(structure['text']);
      }

      if (structure['id_functional_type'] == 117) {
        structure['items'] = [];

        if (structure['child']) {
          for (let key2 in structure['child']) {
            if (structure['child'][key2]['id_functional_status_general'] == 2) continue;
            let item = {};
            item['title'] = structure['child'][key2]['label'];
            item['image'] = structure['child'][key2]['url'];
            item['text'] = structure['child'][key2]['text'];
            item['description'] = structure['child'][key2]['description'];
            item['element'] = structure['child'][key2];
            item['orden'] = +key2;
            item['styleParsed'] = structure['child'][key2]['styleParsed'];
            item['class_custom'] = structure['child'][key2]['class_custom'];
            item['id'] = structure['child'][key2]['id'];
            item['id_function'] = structure['child'][key2]['id_function'];
            item['structure'] = structure['child'][key2];
            item['structure_parent'] = structure;

            structure['items'].push(item);
          }
        }
        if (structure['items'].length == 0) structure['id_functional_status_general'] = 2;
      }
    }
    if(structure['id_functional_type'] == 3 && !structure['creatingMode']) {
      if(structure['child_initial'] === undefined) structure['child_initial'] = this.cloneVariable(structure['child']);
      if(structure['tableFirstView'] === undefined) {
        structure['tableFirstView'] = true;
        let idFa = structure['id_functional_area'];
        let actual_id_functional_area = this.getActualInitialFA(structure);
        if(!this.checkIfParamExists(actual_id_functional_area, structure)) {
          this.paramControlVariablesFAs[actual_id_functional_area][this.paramControlVariables[actual_id_functional_area]['indexParam']][structure['id_functional_area']] = {};
        }
        if(this.paramControlVariablesFAs[actual_id_functional_area][this.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['views'] === undefined) this.paramControlVariablesFAs[actual_id_functional_area][this.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['views'] = [];
        if(this.paramControlVariablesFAs[actual_id_functional_area][this.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['views'].length && this.paramControlVariablesFAs[actual_id_functional_area][this.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['views'].length > 0) {
          for(let i in this.paramControlVariablesFAs[actual_id_functional_area][this.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['views']) {
            if(this.paramControlVariablesFAs[actual_id_functional_area][this.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['views'][i]['active']) {
              if(!structure['initApplied']) {
                structure['viewsInitialized'] = this.cloneVariable(this.paramControlVariablesFAs[actual_id_functional_area][this.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['views'][i]['views']);
              } else {
                this.applyViewTable(this.paramControlVariablesFAs[actual_id_functional_area][this.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['views'][i]['views'], structure);
              }
              structure['hasViewActive'] = true;
              break;
            }
          }
        }
      }
    }
    this.addNewElementArray(structure, key);
    //this.evaluateMyDataValidities(structure);
    structure['validatedFrontend'] = true;
  }

  public setOriginalInputsValues(structure, key) {
    if (
      this.originalInputsValues[
      structure[key]["id_functional_area"] + "-" + structure[key]["bd_field"]
      ] === undefined
    ) {
      this.originalInputsValues[
        structure[key]["id_functional_area"] + "-" + structure[key]["bd_field"]
      ] = this.cloneVariable(
        structure[key]["form"]["controls"][
        structure[key]["id_functional_area"] +
        "-" +
        structure[key]["bd_field"]
        ]
      );
    }
  }

  returnZero() {
    return 0;
  }

  setWindowChanges(id, changes) {
    if(!this.windowChanges[id]) this.windowChanges[id] = {};
    if(this.paramControlVariables[id] && this.paramControlVariables[id]['indexParam']) {
      this.windowChanges[id][this.paramControlVariables[id]['indexParam']] = changes;
    } else {
      this.windowChanges[id][0] = changes;
    }
  }

  assignClass(subStructure, key) {
    if (subStructure["id_functional_area"] == 31713) console.log("ASSIGN CLASS", subStructure, key);
    let orderkey = key + 1;
    if (subStructure["class_custom"] && subStructure["class_custom"] !== null && subStructure["class_custom"] != undefined && subStructure["class_custom"] !== "") {
      if (subStructure["icon"] && subStructure["icon"] !== null && subStructure["icon"] != undefined && subStructure["icon"] !== "") {
        subStructure["class_custom"] = subStructure["class_custom"] + " " + subStructure["icon"] + "-icon-generic yes-icon-generic generic-type-" + subStructure["id_functional_type"] + " generic-order-" + orderkey;
      } else {
        subStructure["class_custom"] = subStructure["class_custom"] + " no-icon-generic generic-type-" + subStructure["id_functional_type"] + " generic-order-" + orderkey;
      }
    } else {
      if (subStructure["icon"] && subStructure["icon"] !== null && subStructure["icon"] != undefined && subStructure["icon"] !== "") {
        subStructure["class_custom"] = subStructure["icon"] + "-icon-generic yes-icon-generic generic-type-" + subStructure["id_functional_type"] + " generic-order-" + orderkey;
      } else {
        subStructure["class_custom"] = "no-icon-generic generic-type-" + subStructure["id_functional_type"] + " generic-order-" + orderkey;
      }
    }
  }

  getDataAccount() {
    return {
      languageId: this.authService.languageId,
      idCompanyGeneric: this.authService.getIdCompany(true),
      idUser: this.authService.getLoggedInUserId(),
      isStatic: this.staticHTML
    };
  }

  public loadExpansion(row: any, from = 0) {
    if (row !== undefined && row !== null) {
      let structure = this.parentStructure;
      if (this.routingService.externalWindowStructure && this.routingService.externalWindowStructure.length > 0) structure = this.routingService.externalWindowStructure;
      else if (from == 1) structure = this.authService.headerStructure;
      else if (from == 2) structure = this.authService.footerStructure;
      this.fillExpansion(row, structure);
      this.heightTableChange = true;
    }
  }

  public fillExpansion(row, subStructure) {
    if (subStructure) {
      for (let e in subStructure) {
        if (subStructure[e]["text"] !== null && subStructure[e]["text"] !== undefined) {
          if (subStructure[e]["text"].toString().includes("*{$")) {
            subStructure[e]["plantillaText"] = JSON.parse(JSON.stringify(subStructure[e]["text"]));
          }
        }
        if ((subStructure[e]["type"] == "img" || subStructure[e]["id_functional_type"] == 5) && subStructure[e]["url"] !== null && subStructure[e]["url"] !== undefined) {
          if (subStructure[e]["url"].toString().includes("*{$")) {
            subStructure[e]["plantillaUrl"] = JSON.parse(JSON.stringify(subStructure[e]["url"]));
          }
        }
        if (subStructure[e]["description"] !== null && subStructure[e]["description"] !== undefined) {
          if (subStructure[e]["description"].toString().includes("*{$")) {
            subStructure[e]["plantillaDescription"] = JSON.parse(JSON.stringify(subStructure[e]["description"]));
          }
        }
        if (subStructure[e]["plantillaText"]) {
          this.changeAllTagsWithValues(subStructure[e], row, 1);
        }
        if (subStructure[e]["plantillaUrl"]) {
          this.changeAllTagsWithValues(subStructure[e], row, 2);
        }
        if (subStructure[e]["plantillaDescription"]) {
          this.changeAllTagsWithValues(subStructure[e], row, 3);
        }
        //Check status expansion with id_db, bd_table, bd_field
        this.changeExpansionStatus(subStructure[e], row);
        this.fillExpansion(row, subStructure[e]["child"]);
      }
    }
  }

  public changeExpansionStatus(structure, row) {
    if ((structure["id_db"] !== null && structure["id_db"] !== undefined && structure["bd_table"] !== null && structure["bd_table"] !== undefined && structure["bd_field"] !== null &&
      structure["bd_field"] !== undefined && structure["id_condition"] == 10) || structure["id_condition"] == 11) {
      let tag = "exp*" + structure["id_db"] + "." + structure["bd_table"] + "." + structure["bd_field"] + "-" + structure["id_functional_area"];
      if (row[tag] !== null && row[tag] !== undefined) {
        if (structure["id_condition"] == 10) structure["expansionStatus"] = 1;
        else if (structure["id_condition"] == 11) structure["expansionStatus"] = 2;
      } else {
        if (structure["id_condition"] == 10) structure["expansionStatus"] = 2;
        else if (structure["id_condition"] == 11) structure["expansionStatus"] = 1;
      }
    }
  }

  public changeAllTagsWithValues(structure, row, type) {
    let row2 = [];
    let faColumns = [];
    for (let r in row) {
      let newLabelRow = r.split("-")[0];
      row2[newLabelRow] = row[r];
      let faNew = this.findElementWithId(newLabelRow, false, false, true);
      if (faNew) faColumns.push(faNew);
    }

    let str: string = "";
    let finalString = "";
    let finalStringTrafficLights = "";

    if (type == 1) {
      str = structure["plantillaText"].toString();
      finalStringTrafficLights = structure["text"];
    } else if (type == 2) {
      str = structure["plantillaUrl"].toString();
      finalStringTrafficLights = structure["url"];
    } else if (type == 3) {
      str = structure["plantillaDescription"].toString();
      finalStringTrafficLights = structure["description"];
    }

    let splits = str.split("*");

    if (structure["id_functional_type"] != 124) {
      for (let s of splits) {
        if (s.includes("{$") && s.includes("$}")) {
          let tagId = s.split("$")[1];
          let value = null;
          if (row2["exp*" + tagId]) {
            value = row2["exp*" + tagId];
          } else {
            let elementsTag = tagId.split(".");
            let id_db = null;
            let bd_table = null;
            let bd_field = null;
            if (elementsTag[0]) id_db = elementsTag[0];
            if (elementsTag[1]) bd_table = elementsTag[1];
            if (elementsTag[2]) bd_field = elementsTag[2];

            for (let i in faColumns) {
              if (faColumns[i]['id_db'] == id_db && faColumns[i]['bd_table'] == bd_table && faColumns[i]['bd_field'] == bd_field) {
                value = row2[faColumns[i]['id_functional_area']];
                break;
              }
            }
          }
          if (value === null || value === undefined) value = "";
          finalString += value;
        } else {
          finalString += s;
        }
      }
      finalString = finalString.replace(/(?:\r\n|\r|\n)/g, "<br>");
      if (type == 1) {
        if (structure["text"] !== null && structure["text"] !== undefined) structure["text"] = finalString;
      } else if (type == 2) {
        if (structure["url"] !== null && structure["url"] !== undefined) structure["url"] = finalString;
      } else if (type == 3) {
        if (structure["description"] !== null && structure["description"] !== undefined) structure["description"] = finalString;
      }
    } else {
      let tagId = str.split("$")[1];
      finalStringTrafficLights = finalStringTrafficLights.replace(/\*.*\*/, "");
      structure["trafficLightValue"] = row2["exp*" + tagId];
      if (type == 1) {
        structure["text"] = finalStringTrafficLights;
      } else if (type == 2) {
        structure["url"] = finalStringTrafficLights;
      } else if (type == 3) {
        structure["description"] = finalStringTrafficLights;
      }
    }
  }

  getNumChanges() {
    let changes = 0;
    if (this.currentInitialArea["key"]) {
      let key = this.currentInitialArea["key"];
      if(this.paramControlVariables[key]) {
        let indexParam = this.paramControlVariables[key]['indexParam'];
        if (this.formsChanged[key] && this.formsChanged[key][indexParam]) {
          for (let i in this.formsChanged[key][indexParam]) {
            if (!this.formsChanged[key][indexParam][i]["isFieldComodin"]) {
              changes++;
            }
          }
        }
      }
    }
    return changes;
  }

  public processThumbnails(item): String[] {
    if (
      isPlatformServer(this.platformId) ||
      item == null ||
      item.thumbnails == null
    ) {
      return [];
    }
    if (this.webpSupported) {
      const webp = item.thumbnails.filter(
        (t) => t.substr(-4, 4).toLowerCase() === "webp"
      );
      if (webp.length > 0) {
        return webp;
      }
    }
    const ret = item.thumbnails.map((t) => t.substr(0, t.length - 4) + "jpg");
    return ret;
  }

  public processFotos(item): String[] {
    if (
      isPlatformServer(this.platformId) ||
      item == null ||
      item.fotos == null
    ) {
      return [];
    }
    if (this.webpSupported) {
      const webp = item.fotos.filter(
        (t) => t.substr(-4, 4).toLowerCase() === "webp"
      );
      if (webp.length > 0) {
        return webp;
      }
    }
    const ret = item.fotos.map((t) => t.substr(0, t.length - 4) + "jpg");
    return ret;
  }

  public prepareHrefPage(url: string) {
    if (!/^https?:\/\//i.test(url)) {
      return "http://" + url;
    } else {
      return url;
    }
  }

  public prepareWebLinkToShow(url: string) {
    return url.replace(/^(?:https?:\/\/)?/i, "").split("/")[0];
  }

  public round(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  public scrollToTop(): void {
    window.scroll(0, 0);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  scroll(id) {
    setTimeout(() => {
      let yOffset = this.headHeight; // Headers
      if (this.authService.isUserSessionActive()) yOffset += this.headHeight;
      let box = document.getElementsByClassName('notificacion-superior-class')[0].getBoundingClientRect();
      yOffset += box.height;
      let element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      //window.scroll(0, y);
      //window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }, 250);
  }

  writeScrollPosition(scrollPos) {
    this.scrollValue = scrollPos;
    this.scrollPostionY.next(this.scrollValue);
  }

  getHeadScrollHeight() {
    return this.headHeight;
  }

  /*public processImages(data: any) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id !== -1) {
        const aux = this.processImage(data[i]);
        data[i].imagen = aux;
      };
      //data[i].thumbnails = this.processThumbnails(data[i]);
    }
  }*/

  public processImage(item): String {
    const imagen = item.imagen;
    /*const auxLength = imagen.split('.').length;
    const format = imagen.split('.')[auxLength-1];
    if (isPlatformServer(this.platformId) || item == null || imagen == null) {
      return '';
    }
    if (this.webpSupported) {
      // const aux = imagen.substr(-4, 4).toLowerCase();
      if (format === 'webp') {
        return imagen;
      }
    }
    if (format === 'jpg') {
      return imagen;
    } else {
      const aux = imagen.substr(-4, 4).toLowerCase() + '.jpg';
      return aux;
    }*/
    return imagen;
  }

  public stopPropagation() {
    event.stopPropagation();
  }

  public findFAWithTableField(db, table, field, idDSB, NGForIndex: any = -1, ignoreStatus = true, ignoreFormField = false, sendAllElements = false) {
    let struct = this.getAllStructures(3, false, idDSB > 0);
    if(sendAllElements) return struct[db + "-" + table + "-" + field];
    if (NGForIndex && typeof NGForIndex == "string") NGForIndex = NGForIndex.replaceAll("null", "");
    for (let i in struct[db + "-" + table + "-" + field]) {
      let ngForId = struct[db + "-" + table + "-" + field][i]['duplicate_id_functional_area'];
      if (ngForId) ngForId = ngForId.toString().replaceAll("null", "");
      let isStatus = (struct[db + "-" + table + "-" + field][i]['id_functional_status_general'] != 2 && this.checkStatus(i));
      if ((ignoreStatus || (isStatus)) && (NGForIndex == -1 || NGForIndex == ngForId || (!ngForId && isStatus)) && struct[db + "-" + table + "-" + field][i]["id_functional_parent_initial_dsb"] == idDSB && (ignoreFormField || struct[db + "-" + table + "-" + field][i]["form_field"] == 1)) {
        return struct[db + "-" + table + "-" + field][i];
      }
    }
    return null;
  }

  public checkStatus(i) {
    return this.checkParentsStatus(this.findElementWithId(i, false, false, true));
  }

  private convertIfIsInteger(valor: any) {
    let originValue = this.cloneVariable(valor);
    // Verifica si el valor es un número entero
    if ((typeof valor === "number" && Number.isInteger(valor)) || (valor.toString().includes(".") && !valor.toString().includes(","))) {
      return valor; // Si ya es un entero, simplemente lo retorna
    }
  
    // Intenta convertirlo a número y verifica que sea entero
    const numeroConvertido = Number(valor);
  
    // Verifica si la conversión fue exitosa y si es un número entero
    if (!isNaN(numeroConvertido) && Number.isInteger(numeroConvertido)) {
      return numeroConvertido;
    }
  
    // Si no es un entero, retorna el valor
    return originValue;
  }

  public assignValueFunctionalArea(idFA, value) {
    let newValue = this.cloneVariable(value);
    if(idFA && idFA['id_functional_area']) {
      setTimeout(() => {
        if(idFA['id_functional_type'] == 12) newValue = this.convertIfIsInteger(newValue);
        if (idFA["form"] && idFA["form"]["controls"] && idFA["form"]["controls"][idFA["id_functional_area"] + "-" + idFA["bd_field"]]) {
          let parent = idFA['id_functional_parent_initial'];
          if (idFA['id_functional_parent_initial_dsb'] > 0) parent = idFA['id_functional_parent_initial_dsb'];
          let type = this.getTypeFromFieldFA(idFA['id_functional_type'], idFA);
          let lastValueOnExecuteFunction = undefined;
          if (this.formsChanged && this.formsChanged[parent] && this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']] && this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][type] && this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][type]['lastValueOnExecuteFunction'] !== undefined) lastValueOnExecuteFunction = this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][type]['lastValueOnExecuteFunction'];
          if (lastValueOnExecuteFunction === undefined || lastValueOnExecuteFunction != newValue) {
            if(idFA['id_functional_type'] == 12 && idFA['type'] == "autocomplete" && idFA['id_query'] && this.selectOptions[+idFA['id_query']] && this.selectOptions[+idFA['id_query']].length > 0) {
              let results = this.selectOptions[+idFA["id_query"]];
              for(let i in results) {
                if(results[i]['value'] == newValue) {
                  newValue = results[i]['text'];
                  break;
                }
              }
            }
            idFA["form"].patchValue({ [idFA['id_functional_area'] + '-' + idFA["bd_field"]]: newValue });
            idFA['addedAsignValuePreloaded'] = true;
            // idFA["form"]["controls"][idFA["id_functional_area"] + "-" + idFA["bd_field"]].setValue(newValue);
            if (idFA['id_functional_type'] != 12 || (idFA['id_functional_status_general'] && idFA['id_functional_status_general'] == 3)) {
              setTimeout(() => {
                if (idFA["tmp_value"] != newValue) {
                  idFA["tmp_value"] = newValue;
                }
              });
            }
          }
        } else if(idFA["form_field"] == 1) {
          if (idFA["tmp_value"] != newValue) {
            idFA['addedAsignValuePreloaded'] = true;
            idFA["tmp_value"] = newValue;
          }
        }
      });
    }
  }

  public checkParentsStatus(idFA) {
    let parent = this.findElementWithId(idFA['id_functional_parent'], false, false, true);
    if (parent && parent['id_functional_area'] != idFA['id_functional_parent_initial'] && parent['id_functional_parent_initial_dsb'] == idFA['id_functional_parent_initial_dsb']) {
      if (parent['id_functional_status_general'] == 2) return false;
      else return this.checkParentsStatus(parent);
    } else {
      return true;
    }
  }

  public getTypeFromFieldFA(id_functional_type, idFA) {
    let type = "_";
    if (id_functional_type == 6) {
      if (idFA['type'] == 'password') type = 'password_';
      else if (idFA['type'] == '5-stars') type = '5-stars_';
      else if (idFA['type'] == '10-stars') type = '10-stars_';
      else if (idFA['type'] == '3-faces') type = '3-faces_';
      else if (idFA['type'] == '5-faces') type = '5-faces_';
      else type = 'input_';
    } else if (id_functional_type == 12) {
      type = idFA['type'] + '_';
    } else if (id_functional_type == 15) {
      type = idFA['type'] + '_';
    }
    type = type + idFA['id_functional_area'];
    return type;
  }

  public consoleLogFA(fa = false) {
    console.log("formsChanged", this.formsChanged);
    if(fa) {
      console.log(fa);
      let message = "¡COPIADO ID! FA: " + fa['id_functional_area'] + ". Type: " + fa['id_functional_type'] + ".";
      if(fa['id_query']) {
        message += " Query: " + fa['id_query'] + ".";
      }
      this.routingService.showFAInfo = fa;
      this.openSnackBar(message, 7000, ["blue-snackbar"], "start", "top");
    } else {
      this.showAllInfo = true;
    }
  }

  public consoleLogMenu(menu = false) {
    if(menu) {
      console.log(menu);
      let message = "¡COPIADO ID! Menu: " + menu['id_element'];
      this.openSnackBar(message, 7000, ["blue-snackbar"], "start", "top");
    }
  }

  public addNewElementArray(element, key) {
    if (!element['addedNewElementArray']) {
      element['addedNewElementArray'] = true;
      element['keyStrcutureChild'] = key;
      if (element['id_functional_parent_initial_dsb'] > 0 && element['id_functional_parent_initial_dsb'] != element['id_functional_area']) this.dsbStructureArrayFA[element['id_functional_area']] = element;
      else if (element['id_functional_parent_initial'] == this.headerId || element['id_functional_parent_initial'] == this.headerNoLoguedId) this.headerStructureArrayFA[element['id_functional_area']] = element;
      else if (element['id_functional_parent_initial'] == this.footerId || element['id_functional_parent_initial'] == this.footerNoLoguedId) this.footerStructureArrayFA[element['id_functional_area']] = element;
      else if (this.routingService.shouldOpenExternalWindow) this.externalWindowStructureArrayFA[element['id_functional_area']] = element;
      else this.parentStructureArrayFA[element['id_functional_area']] = element;

      if (this.checkFields(element)) {
        let keyArray = element['id_db'] + '-' + element['bd_table'] + '-' + element['bd_field'];
        if (element['id_functional_parent_initial_dsb'] > 0 && element['id_functional_parent_initial_dsb'] != element['id_functional_area']) this.addArrayElementSubArray(this.dsbStructureArrayFields, keyArray, element['id_functional_area'], element);
        else if (element['id_functional_parent_initial'] == this.headerId || element['id_functional_parent_initial'] == this.headerNoLoguedId) this.addArrayElementSubArray(this.headerStructureArrayFields, keyArray, element['id_functional_area'], element);
        else if (element['id_functional_parent_initial'] == this.footerId || element['id_functional_parent_initial'] == this.footerNoLoguedId) this.addArrayElementSubArray(this.footerStructureArrayFields, keyArray, element['id_functional_area'], element);
        else if (this.routingService.shouldOpenExternalWindow) this.addArrayElementSubArray(this.externalWindowStructureArrayFields, keyArray, element['id_functional_area'], element);
        else this.addArrayElementSubArray(this.parentStructureArrayFields, keyArray, element['id_functional_area'], element);
      }

      if (element && element['id_query'] && element['id_query'] > 0) {
        if (element['id_functional_parent_initial_dsb'] > 0 && element['id_functional_parent_initial_dsb'] != element['id_functional_area']) this.dsbStructureArrayQueries[element['id_query']] = element;
        else if (element['id_functional_parent_initial'] == this.headerId || element['id_functional_parent_initial'] == this.headerNoLoguedId) this.headerStructureArrayQueries[element['id_query']] = element;
        else if (element['id_functional_parent_initial'] == this.footerId || element['id_functional_parent_initial'] == this.footerNoLoguedId) this.footerStructureArrayQueries[element['id_query']] = element;
        else if (this.routingService.shouldOpenExternalWindow) this.externalWindowStructureArrayQueries[element['id_query']] = element;
        else this.parentStructureArrayQueries[element['id_query']] = element;
      }

      if (element['id_functional_parent_initial_dsb'] > 0 && element['id_functional_parent_initial_dsb'] != element['id_functional_area']) this.dsbStructureArray.push(element);
      else if (element['id_functional_parent_initial'] == this.headerId || element['id_functional_parent_initial'] == this.headerNoLoguedId) this.headerStructureArray.push(element);
      else if (element['id_functional_parent_initial'] == this.footerId || element['id_functional_parent_initial'] == this.footerNoLoguedId) this.footerStructureArray.push(element);
      else if (this.routingService.shouldOpenExternalWindow) this.externalWindowStructureArray.push(element);
      else this.parentStructureArray.push(element);
    }
  }

  private createAffectedElements(array, arrayAffected) {
    let result = { array: {}, evaluated: true, arrayAffected: {} };
    for (let i in array) {
      for (let j in array[i]) {
        if (!result['array'][j]) result['array'][j] = {};
        result['array'][j][i] = array[i][j];
      }
    }
    for (let i in arrayAffected) {
      for (let j in arrayAffected[i]) {
        for (let k in arrayAffected[i][j]) {
          let id = arrayAffected[i][j][k];
          if (!result['arrayAffected'][id]) result['arrayAffected'][id] = {};
          if (!result['arrayAffected'][id][i]) result['arrayAffected'][id][i] = [];
          result['arrayAffected'][id][i].push(j);
        }
      }
    }
    return result;
  }

  private checkIfHasToCreateAffectedElementsArray(idModule) {
    if (this.affectedElementsFA[idModule] === undefined || !this.affectedElementsFA[idModule]['evaluated']) {
      if (idModule == this.headerId || idModule == this.headerNoLoguedId) {
        this.affectedElementsFA[idModule] = this.createAffectedElements(this.affectedElementsHeader, this.elementsThatAffectOthersHeader);
      } else if (idModule == this.footerId || idModule == this.footerNoLoguedId) {
        this.affectedElementsFA[idModule] = this.createAffectedElements(this.affectedElementsFooter, this.elementsThatAffectOthersFooter);
      } else {
        this.affectedElementsFA[idModule] = this.createAffectedElements(this.affectedElements, this.elementsThatAffectOthers);
      }
    }
  }

  private evaluateMyDataValidities(structure) {
    let idModule = structure['id_functional_parent_initial'];
    if (structure['id_functional_parent_initial_dsb'] && structure['id_functional_parent_initial_dsb'] > 0 && structure['id_functional_parent_initial_dsb'] != structure['id_functional_area']) idModule = structure['id_functional_parent_initial_dsb'];
    this.checkIfHasToCreateAffectedElementsArray(idModule);
    let id_functional_area = structure["id_functional_area_original"] || structure["id_functional_area"];
    id_functional_area = id_functional_area.toString().split(".")[0];
    let isAffectedFor = [];
    let affectedFor = this.affectedElementsFA[idModule];
    if (affectedFor && affectedFor['array'] && affectedFor['array'][id_functional_area]) {
      isAffectedFor = affectedFor['array'][id_functional_area];
    } else {
      return;
    }
    let NGForIndex = -1;
    if (structure["ngForId"] != -1) {
      let elementContents = structure["id_functional_area"].toString().split(".");
      if (elementContents[1] > 0) NGForIndex = elementContents[1];
    }
    for (let i in isAffectedFor) {
      if (affectedFor['arrayAffected'][i]) {
        for (let j in affectedFor['arrayAffected'][i]) {
          let node = this.findElementWithId(j, false, false, true);
          if (node && node["initializedElement"]) {
            setTimeout(() => {
              this.updateDataValidity(node);
            });
          }
        }
      }
    }
  }

  private updateDataValidity(structure) {
    let v = structure["tmp_value"];
    let data_validity_value = v;
    if (v === undefined || v === null || v === '') data_validity_value = -1;
    this.updateStatus(data_validity_value, structure)
  }

  public addArrayElementSubArray(array, keyArray, newKey, element) {
    if (!array[keyArray] || array[keyArray] === undefined) array[keyArray] = [];
    array[keyArray][newKey] = element;
  }

  public cloneVariable(varToClone) {
    let varCloned = _.cloneDeep(varToClone);
    return varCloned;
  }

  public updateParamControl(newLocal = false) {
    this.endpointService.saveUserParams(newLocal, this.authService.userId, this.authService.getIdCompany(true), this.paramControlVariables).subscribe((data) => {
    });
  }

  public updateParamControlFA(idFa, idFa_initial, index, params) {
    this.endpointService.updateParamControlFA(idFa, idFa_initial, index, this.authService.userId, this.authService.getIdCompany(true), params).subscribe((data) => {
    });
  }

  public addOldParamsBackend() {
    let oldLocalParamControl = JSON.parse(localStorage.getItem('paramControl'));
    if (oldLocalParamControl !== null && oldLocalParamControl !== undefined) {
      localStorage.removeItem('paramControl');
    }
  }

  public devModeGenericFunction() {
    this.devModeGenericChanged = true;
    this.checkDevModeGeneric();
  }

  public createNewParamVariable() {
    return { params: [this.initializeParams()], indexParam: 0, orden: Object.values(this.paramControlVariables).length }
  }

  public initializeParams() {
    return { input: [], intern: [], output: [] };
  }


  public initFormChangeField(structure) {
    let parentInit = structure['id_functional_parent_initial'];
    if (structure['id_functional_parent_initial_dsb'] > 0) parentInit = structure['id_functional_parent_initial_dsb'];
    if (!this.paramControlVariables[parentInit]) {
      this.paramControlVariables[parentInit] = { indexParam: 0 };
    }
    if (!this.paramControlVariables[parentInit]['indexParam']) {
      this.paramControlVariables[parentInit]['indexParam'] = 0;
    }
    let indexParam = this.paramControlVariables[parentInit]['indexParam'];
    if (!this.formsChanged[parentInit]) this.formsChanged[parentInit] = {};
    if (!this.formsChanged[parentInit][indexParam]) {
      this.formsChanged[parentInit][indexParam] = {};
    }
    const controlName = structure['id_functional_area'] + '-' + structure['bd_field'];
    if (structure["form"] && structure["form"]["controls"]) {
      if (structure["id_functional_type"] == 12) {
        structure["form"]["controls"][controlName] = new FormControl(
          structure["tmp_value_init"] || structure["tmp_value_init"] === 0 ? structure["tmp_value_init"] : null,
          structure["id_functional_status_general"] == 5 && !structure["creatingMode"] && structure["hide"] == 0 && this.checkSelectionHasResults(structure) ? Validators.required : null
        );
      } else {
        structure["form"]["controls"][controlName] = new FormControl(
          structure["tmp_value_init"] || structure["tmp_value_init"] === 0 ? structure["tmp_value_init"] : null,
          structure["id_functional_status_general"] == 5 && !structure["creatingMode"] && structure["hide"] == 0 ? Validators.required : null
        );
      }
      if (structure["id_functional_status_general"] == 3 || structure["hide"] == 1) {
        structure["form"]["controls"][controlName].disable();
      }
    }
  }

  checkSelectionHasResults(structure) {
    return (structure['id_query'] && this.selectOptions[structure['id_query']] && this.selectOptions[structure['id_query']].length && this.selectOptions[structure['id_query']].length > 0) || structure['creatingMode'];
  }

  public deleteRequiredInFrom(structure) {
    if (structure && structure["form"] && structure["form"]["controls"] && structure["form"]["controls"][structure["id_functional_area"] + "-" + structure["bd_field"]]) {
      structure["form"]["controls"][structure["id_functional_area"] + "-" + structure["bd_field"]].setValidators(null); // Quitar la validación 'required' del campo 'nombre'
    }
  }

  public go(route, checkRouting, id_route = null) {
    this.pushPage({url: route});
    if (!route.includes(this.authService.labelLanguage + "/")) route = this.authService.labelLanguage + "/" + route;
    if (id_route != null) route += "#" + id_route;
    route = route.replace("//", "/");
    let found = false;
    let splited = route.split('#')[0];
    let aux = splited.split('/');
    let myRoute = aux[aux.length - 1];
    let outputParams = null;
    if (this.paramControlVariables[this.routingService.moduleId]) {
      let indexParam = -1;
      if (this.paramControlVariables[this.routingService.moduleId]['indexParam'] !== undefined) indexParam = this.paramControlVariables[this.routingService.moduleId]['indexParam'];
      if (indexParam != -1 && this.paramControlVariables[this.routingService.moduleId]['params'][indexParam] && this.paramControlVariables[this.routingService.moduleId]['params'][indexParam]['output']) {
        outputParams = this.paramControlVariables[this.routingService.moduleId]['params'][indexParam]['output'];
      }
    }
    if (checkRouting) {
      for (let i in this.paramControlVariables) {
        if (this.paramControlVariables[i] && this.paramControlVariables[i]['route_internal'] && this.paramControlVariables[i]['route_internal'] == myRoute && this.routingService.moduleId != i) {
          // Entramos en los parametros de la pantalla que corresponde a la ruta
          for (let j in this.paramControlVariables[i]['params']) {
            let inputParams = this.paramControlVariables[i]['params'][j]['input'];
            // console.log("found Params input", inputParams, outputParams, !(outputParams && outputParams.length && outputParams.length > 0), !(inputParams && inputParams.length && inputParams.length > 0), JSON.stringify(inputParams) == JSON.stringify(outputParams))
            if (!(inputParams && inputParams.length && inputParams.length > 0) || (outputParams && outputParams.length && outputParams.length > 0 && JSON.stringify(inputParams) == JSON.stringify(outputParams))) {
              found = true;
              if (j != this.paramControlVariables[i]['indexParam']) this.paramControlVariables[i]['indexParam'] = j;
              let response = this.swipeTabIndex(this.paramControlVariables[i]['indexParam'], i, route);
              if (response == 2) this.openSnackBar("La pantalla aún no se ha cargado completamente", 7000, ["red-snackbar"]);
              if (response == 1) found = false; // No estava cargado el tab o pantalla todavía
              break;
            }
          }
        }
        if(found) break;
      }
    }
    if (!found) {
      this.routingService.go(route);
    } else {
      this.routingService.closeOtherComponents();
    }
  }

  swipeTabIndex(index, id_pantalla, route) {
    this.tabChanging = true;
    let indexParam = this.cloneVariable(this.paramControlVariables[id_pantalla]['indexParam']);
    this.paramControlVariables[id_pantalla]['indexParam'] = index;
    if (id_pantalla != this.routingService.moduleId) { // Son pantallas diferentes
      return this.openTabGeneric(id_pantalla, route);
    } else if (indexParam != index) { // Son indices diferentes de la misma pantalla
      this.paramControlVariables[id_pantalla]['indexParam'] = index;
      return this.openTabGeneric(id_pantalla, route);
    }
    return 3; // He clicado donde estoy
  }

  public openTabGeneric(key, route) {
    if (!this.finishedLoadStructure) {
      return 2;
    }
    let routeAux = this.cloneVariable(route);
    if (!route.includes(this.authService.labelLanguage + "/")) routeAux = this.authService.labelLanguage + "/" + routeAux;

    this.parentStructure = [];
    this.finishedLoadStructure = false;
    this.tabChanging = true;
    let oldRouting = this.cloneVariable(this.routingService.moduleId);
    let indexParam = this.paramControlVariables[key]["indexParam"];
    this.routingService.moduleId = key;
    if (this.arrayFunctionalParentsLoaded[key] && this.arrayFunctionalParentsLoaded[key][indexParam] && (!this.paramControlVariables[key]['has_to_reaload'] || this.paramControlVariables[key]['has_to_reaload'] != 1)) {
      let elem = this.arrayFunctionalParentsLoaded[key][indexParam];
      this.structureTab = elem['structure'];
      this.structure = elem['structure'];
      this.currentInitialArea = elem['currentInitialArea'];
      this.parentStructure = this.structure['child'];
      this.elementsThatAffectOthers = elem['elementsThatAffectOthers'];
      this.affectedElements = elem['affectedElements'];
      this.selectOptions = elem['selectOptions'];
      this.tableData = elem['tableData'];
      this.parentStructureArray = elem['parentStructureArray'];
      this.parentStructureArrayFA = elem['parentStructureArrayFA'];
      this.formsIds = elem['formsIds'];
      this.parentStructureArrayFields = elem['parentStructureArrayFields'];
      this.parentStructureArrayQueries = elem['parentStructureArrayQueries'];
      if (elem['queriesInfoDebbug'] !== undefined && elem['queriesInfoDebbug'] !== null) this.queriesInfoDebbug = elem['queriesInfoDebbug'];
      if (elem['timmingsInfoDebbug'] !== undefined && elem['timmingsInfoDebbug'] !== null) this.timmingsInfoDebbug = elem['timmingsInfoDebbug'];
      if (elem['hideColumnsTable'] !== undefined && elem['hideColumnsTable'] !== null) this.hideColumnsTable = elem['hideColumnsTable'];
      if (this.paramControlVariables[key]['breadcrumbs']) this.breadcrumbs = this.cloneVariable(this.paramControlVariables[key]['breadcrumbs']).reverse();
      this.paramControlVariables[key]['active'] = 1;
      // Hay que poner timeout sino los breadcrumbs (this.breadcrumbs de arriba) modifican antes el url de arriba. Si vas jugando con los que estan cargados hay un momento que falla. Habría que mirar porque breadcrumbs lo cambian
      setTimeout(() => {
        this.routingService.changeUrl(routeAux);
        if (this.paramControlVariables[key]['has_to_reaload'] && this.paramControlVariables[key]['has_to_reaload'] == 2) this.updateResults(this.getInternParam(this.structure, []), this.structure['id_functional_parent_initial']);
      });
      return 0;
    } else {
      this.routingService.moduleId = oldRouting;
      this.finishedLoadStructure = true;
      this.routingService.changeUrl(routeAux);
      this.routingService.prepareRouting();
      return 1;
    }
  }

  public openSnackBar(msg, duration, panelClass = ["green-snackbar"], hPosition: any = "center", vePosition: any = "bottom") {
    const snackbarRef = this.snackBar.open(msg, "x", {
      duration: duration,
      panelClass: panelClass,
      horizontalPosition: hPosition,
      verticalPosition: vePosition
    });
    this.snackbarOpened = true;
    // Para verificar si el snackbar está abierto, puedes hacerlo de la siguiente manera:
    snackbarRef.afterDismissed().subscribe(() => {
      this.snackbarOpened = false;
    });
  }

  public getInternParam(structure, param, isMassive = 0) {
    let isExternalWindow = this.routingService.shouldOpenExternalWindow && this.authService.externalWindowStructure !== null && this.authService.externalWindowStructure !== undefined;
    let idFAInitial = +structure["id_functional_parent_initial"];
    if (!this.paramControlVariables || !this.paramControlVariables[idFAInitial] || isExternalWindow) {
      this.paramControlVariables[idFAInitial] = this.createNewParamVariable();
    }

    let par = this.paramControlVariables[idFAInitial];
    if(isMassive == 0) par["params"][par["indexParam"]]["intern"] = [];
    if (structure["id_functional_type"] != 3 || isMassive == 1) {
      let paramInput = null;
      if (Array.isArray(param) && param.length > 0) {
        for (let p of param) {
          if (p && p["param_intern"]) paramInput = p["param_intern"];
          this.findInterns(structure["idFAParamsInterns"], par["params"][par["indexParam"]], paramInput, structure["id_functional_parent_initial_dsb"]);
        }
      } else {
        if (param && param["param_intern"]) paramInput = param["param_intern"];
        this.findInterns(structure["idFAParamsInterns"], par["params"][par["indexParam"]], paramInput, structure["id_functional_parent_initial_dsb"]);
      }
    } else { //table
      if (Array.isArray(param) && param.length > 0) {
        for (let p of param) {
          this.getParamInternTable(p["param_intern"], par['params'][par["indexParam"]], structure, "intern", true);
        }
      } else {
        if (param && param["param_intern"]) this.getParamInternTable(param["param_intern"], par['params'][par["indexParam"]], structure);
      }
    }
    return par["params"][par["indexParam"]]["intern"];
  }


  findInterns(subStructure: any, param, tableParams, idDSB, where = "intern") {
    console.log(param, "wffss", subStructure)
    if (!param || param === undefined || !param[where]) return;
    for (let i in subStructure) {
      let element = this.findElementWithId(subStructure[i], false, false, true);
      if (!element || element == null && element === undefined) continue;
      let getVal = "tmp_value";
      if (element !== null && element !== undefined && element["id_functional_status_general"] != 2 && ((where == "intern" && element["param_intern"] == 1) || (where == "output" && element["param"] == 1)) && element["id_functional_parent_initial_dsb"] == idDSB) {
        if (this.checkParentsStatus(element)) {
          let found = false;
          if (element['tmp_value_multi_chip'] && element['tmp_value_multi_chip'] !== undefined && element['is_function_selection_multiple'] !== undefined && element['is_function_selection_multiple']) getVal = "tmp_value_multi_chip";
          if (element["id_functional_type"] != 9 && element[getVal]) {
            for (let p in param[where]) {
              if (param[where][p]["id_db"] == element["id_db"] && param[where][p]["bd_table"] == element["bd_table"] && param[where][p]["bd_field"] == element["bd_field"]) {
                param[where][p]["value"] = element[getVal];
                found = true;
                break;
              }
            }
            if (!found) this.pushInterns(element, param, tableParams, getVal, where);
          } else if (tableParams !== null) {
            for (let e in tableParams) {
              if (e.split("-")[0] == element["bd_table"] && e.split("-")[1] == element["bd_field"]) {
                for (let p in param[where]) {
                  if (param[where][p]["id_db"] == element["id_db"] && param[where][p]["bd_table"] == element["bd_table"] && param[where][p]["bd_field"] == element["bd_field"]) {
                    param[where][p]["value"] = tableParams[e];
                    if (param[where][p]["value"] == "isbooleanfalse") param[where][p]["value"] = 0;
                    if (param[where][p]["value"] == "isbooleantrue") param[where][p]["value"] = 1;
                    found = true;
                    break;
                  }
                }
                if (!found) this.pushInterns(element, param, tableParams, getVal, where);
              }
            }
          }
        }
      }
    }
  }

  pushInterns(subStructure, param, loopParams, getVal, where) {
    if (subStructure["id_functional_type"] != 9) {
      param[where].push({
        id_db: subStructure["id_db"],
        bd_table: subStructure["bd_table"],
        bd_field: subStructure["bd_field"],
        value: subStructure[getVal],
      });
    } else if (loopParams !== null) {
      let aux = null;
      for (let e in loopParams) {
        if (e.split("-")[1] == subStructure["bd_field"] && e.split("-")[0] == subStructure["bd_table"]) {
          aux = loopParams[e];
          if (aux == "isbooleanfalse") aux = 0;
          if (aux == "isbooleantrue") aux = 1;
          break;
        }
      }
      param[where].push({
        id_db: subStructure["id_db"],
        bd_table: subStructure["bd_table"],
        bd_field: subStructure["bd_field"],
        value: aux,
      });
    }
  }


  public getParamInternTable(param: any, par: any, structure: any, where = "intern", isArray = false) {
    for (let aux in param) {
      if (param[aux] == "isbooleantrue") param[aux] = 1;
      if (param[aux] == "isbooleanfalse") param[aux] = 0;
      if (this.hasBDField(param, aux)) {
        if (!isArray) {
          let found = false;
          for (let p of par[where]) {
            if (p["bd_field"] == aux.split("-")[1] && p["bd_table"] == aux.split("-")[0]) {
              found = true;
              p["value"] = param[aux];
              //break;
            }
          }
          if (!found) {
            par[where].push({
              id_db: structure["id_db"],
              bd_table: aux.split("-")[0],
              bd_field: aux.split("-")[1],
              value: param[aux],
              isArray: isArray
            });
          }
        } else {
          par[where].push({
            id_db: structure["id_db"],
            bd_table: aux.split("-")[0],
            bd_field: aux.split("-")[1],
            value: param[aux],
            isArray: isArray
          });
        }
      }
    }
  }

  private hasBDField(params, field) {
    for (let p in params) {
      if (p.includes(field.split("-")[1])) return true;
    }
    return false;
  }

  public clearParamsWindow(id_functional_parent_initial) {
    if (!this.paramControlVariables[id_functional_parent_initial]) {
      this.paramControlVariables[id_functional_parent_initial] = this.createNewParamVariable();
    }
    let params = this.paramControlVariables[id_functional_parent_initial];
    params['params'][params['indexParam']] = this.initializeParams();
  }




  checkFilterStringObject(value, filter) {
    if (typeof value === 'string') {
      // Si el valor es una cadena, realizar la comparación
      let cleanedValue = this.limpiarCadena(value);
      return cleanedValue.includes(filter);
    } else if (typeof value === 'number') {
      // Si el valor es un número, comparar directamente
      let cleanedValue = this.limpiarCadena(value.toString());
      return cleanedValue.includes(filter); // Puedes ajustar según tus necesidades
    } else if (Array.isArray(value)) {
      // Si el valor es un array, iterar sobre sus elementos y llamar recursivamente a la función
      for (let i = 0; i < value.length; i++) {
        if (this.checkFilterStringObject(value[i], filter)) {
          return true;
        }
      }
    } else if (typeof value === 'object' && value !== null) {
      // Si el valor es un objeto, iterar sobre sus propiedades y llamar recursivamente a la función
      for (let key in value) {
        if (value.hasOwnProperty(key)) {
          if (this.checkFilterStringObject(value[key], filter)) {
            return true;
          }
        }
      }
    }

    // Si no se encuentra ninguna coincidencia, retorna false
    return false;
  }

  limpiarCadena(cadena: string): string {
    // Quitar espacios
    let sinEspacios = cadena.replace(/\s/g, '');

    // Convertir a minúsculas
    let enMinusculas = sinEspacios.toLowerCase();

    // Quitar acentos
    let sinAcentos = enMinusculas.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    return sinAcentos;
  }

  formatDate(date, withSeconds = true) {
    if (date != "" && date !== null && date !== undefined) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hours = "" + d.getHours(),
        mins = "" + d.getMinutes(),
        seconds = "" + d.getSeconds();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (hours.length < 2) hours = "0" + hours;
      if (mins.length < 2) mins = "0" + mins;
      if (seconds.length < 2) seconds = "0" + seconds;
      if (withSeconds) return ([year, month, day].join("-") + " " + [hours, mins, seconds].join(":"));
      else return [year, month, day].join("-");
    } else return null;
  }

  getRegsitrySelectionFromFA(fa) {
    let results = this.selectOptions[fa['id_query']];
    if (fa['tmp_value']) {
      for (let i in results) {
        if (results[i]['value'] == fa['tmp_value']) {
          return results[i];
        }
      }
    }
    return {};
  }

  openHelpExpansion(name: string) {
    setTimeout(() => {
      this.openHelpExpansionName = name;
    }, 300);
  }

  cleanView(fa) {
    fa['child'] = this.cloneVariable(fa['child_initial']);
    fa['hasViewActive'] = false;
    fa = this.findElementWithId(fa['id_functional_area'], false, false, true);
    fa['showSub'] = true;
    fa['id_functional_status_general_statusInitView'] = this.cloneVariable(fa['id_functional_status_general']);
    fa['id_functional_status_general'] = 2;
    for(let i in fa['child']) {
      fa['child'][i]['hide'] = this.cloneVariable(fa['child'][i]['hide_initial']);  
    }
    setTimeout(() => {
      fa['showSub'] = false;
      fa['id_functional_status_general'] = fa['id_functional_status_general_statusInitView'];
      fa['wasInitialized'] = false;
    });
  }

  applyViewTable(columns, fa) {
    // Encuentra el elemento funcional y ajusta su estado inicial
    fa = this.findElementWithId(fa['id_functional_area'], false, false, true);
    fa['showSub'] = true;
    fa['id_functional_status_general_statusInitView'] = this.cloneVariable(fa['id_functional_status_general']);
    fa['id_functional_status_general'] = 2;

    // Ajusta el estado de los elementos y prepara columnsView en un solo bucle
    const columnsView = {};
    let i = 0;
    for (let c in columns) {
        let idFaSplited = this.cloneVariable(columns[c]['column']).split('-');
        let idFa = null;
        if (idFaSplited[0]) idFa = this.findElementWithId(idFaSplited[0], false, false, true);
        if (idFa) {
            if (this.cloneVariable(columns[i]['displayed'])) {
                idFa['hide'] = 0;
            } else {
                idFa['hide'] = 1;
            }
            columnsView[idFaSplited[0]] = i;
            ++i;
        }
    }
    
    // Ordenar fa['child'] usando columnsView como referencia
    fa['child'].sort((a, b) => {
        let idA = a['id_functional_area'].toString();
        let idB = b['id_functional_area'].toString();

        // Si ambos idA e idB están en columnsView, ordenar según su posición en columnsView
        if (idA in columnsView && idB in columnsView) {
            return columnsView[idA] - columnsView[idB];
        }
        // Si sólo idA está en columnsView, idA va primero
        else if (idA in columnsView) {
            return 1;
        }
        // Si sólo idB está en columnsView, idB va primero
        else if (idB in columnsView) {
            return -1;
        }
        // Si ninguno está en columnsView, mantener el orden original
        else {
            return 0;
        }
    });

    this.tableData[fa['id_functional_area']]['columns'] = [];
    let orden = 0;
    for(let i in fa['child']) {
      fa['child'][i]['order_general'] = orden;
      fa['child'][i]['order_auto_cron'] = orden;
      if(fa['child'][i]['type'] != "expansion" && fa['child'][i]['id_functional_type'] == 9 && fa['child'][i]['bd_table'] && fa['child'][i]['bd_field']) {
        let obj = fa['child'][i]['id_functional_area'] + "-" + fa['child'][i]['bd_field'];
        this.tableData[fa['id_functional_area']]['columns'].push(obj);
      }
      ++orden;
    }

    // Revertir el estado después de una pequeña demora
    setTimeout(() => {
      fa['showSub'] = false;
      fa['id_functional_status_general'] = fa['id_functional_status_general_statusInitView'];
      fa['wasInitialized'] = false;
    }, 0);
  }

  getActualInitialFA(structure) {
    let actual_id_functional_area = structure['id_functional_parent_initial'];
    if(structure['id_functional_parent_initial_dsb'] > 0) {
      actual_id_functional_area = structure['id_functional_parent_initial_dsb'];
    }
    return actual_id_functional_area;
  }

  checkIfParamExists(actual_id_functional_area, element) {
    if(this.paramControlVariables[actual_id_functional_area] === undefined) this.paramControlVariables[actual_id_functional_area] = {};
    if(this.paramControlVariables[actual_id_functional_area]['indexParam'] === undefined) this.paramControlVariables[actual_id_functional_area]['indexParam'] = 0;
    if(this.paramControlVariablesFAs[actual_id_functional_area] === undefined) this.paramControlVariablesFAs[actual_id_functional_area] = [];
    if(this.paramControlVariablesFAs[actual_id_functional_area][this.paramControlVariables[actual_id_functional_area]['indexParam']] === undefined) this.paramControlVariablesFAs[actual_id_functional_area][this.paramControlVariables[actual_id_functional_area]['indexParam']] = new Map();
    return this.paramControlVariablesFAs[actual_id_functional_area][this.paramControlVariables[actual_id_functional_area]['indexParam']][element['id_functional_area']] !== undefined;
  }

  revertAllWindowChanges() {
    let formsChanged = this.getFormsCurrentPage();
    for (let i in formsChanged) {
      const elem = this.findElementWithId(formsChanged[i]['id_functional_area'], false, false, true);
      this.revertFormValue(elem, this.currentInitialArea['key'], formsChanged[i]['name']);
    }
  }

  public async askRevertFormValue(parent, input) {
    const id = this.getId(input['value']['name']);
    const elem = this.findElementWithId(id, false, false, true);
    if (elem) {
      const status = await this.openWarningDialog(2, 0);
      if (status) {
        this.revertFormValue(elem, parent, input.value.name);
      }
    }
  }

  revertFormValue(elem, parent, name) {
    console.log("revertFormValue",this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']])
    elem['tmp_value'] = this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][name]['internal_value']['old'];
    if (elem['type'] == '5-stars' || elem['type'] == '10-stars' || elem['type'] == '3-faces' || elem['type'] == '5-faces') {
      elem['starsOnHoverValue'] = this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][name]['internal_value']['old'] - 1;
      if (elem['type'] == '5-stars' || elem['type'] == '10-stars') {
        for (let star in elem['starsArray']) {
          if (+star <= elem['starsOnHoverValue']) elem['starsArray'][star] = true;
          else elem['starsArray'][star] = false;
        }
      }
      else if (elem['type'] == '3-faces' || elem['type'] == '5-faces') {
        for (let star in elem['starsArray']) {
          if (+star == elem['starsOnHoverValue']) elem['starsArray'][star] = true;
          else elem['starsArray'][star] = false;
        }
      }
    }
    if (elem["form"]["controls"][elem['id_functional_area'] + '-' + elem["bd_field"]]) {
      elem["form"]["controls"][elem['id_functional_area'] + '-' + elem["bd_field"]].setValue(this.formsChanged[parent][this.paramControlVariables[parent]['indexParam']][name]['internal_value']['old']);
      this.deleteFormHistory(parent, name);
      if (elem['type'] == "slide" || elem['type'] == "checkbox") this.updateValueCheckBox(elem, elem['tmp_value']);
      if (elem['type'] == "password" && elem.hasTmpValuePw !== undefined) {
        elem['form_field'] = 0;
        elem.hasTmpValuePw = true;
      }
    }
  }

  public getId(key) {
    const id = key.split('_')[1];
    return id;
  }

  getFormsCurrentPage(parent_initial = 0, getIsFieldComodin = false) {
    if (parent_initial === 0) parent_initial = this.currentInitialArea["key"];
    if (this.formsChanged[parent_initial] && this.formsChanged[parent_initial][this.paramControlVariables[parent_initial]['indexParam']]) return this.orderFormsChanged(this.formsChanged[parent_initial][this.paramControlVariables[parent_initial]['indexParam']], getIsFieldComodin);
    return {};
  }
  
  public orderFormsChanged(formsChanged, getIsFieldComodin) {
    let ret = {};
    for (let i in formsChanged) {
      if (getIsFieldComodin || !formsChanged[i]["isFieldComodin"]) {
        let split = i.split("_");
        formsChanged[i]["id_functional_area"] = split[1];
        formsChanged[i]["type"] = split[0] + "_";
        formsChanged[i]["label"] = this.getLabel(split[1]);
        ret[formsChanged[i]["id"]] = formsChanged[i];
      }
    }
    return ret;
  }

  public getLabel(idFa) {
    // Cas de que es un canvi normal
    if (!this.toolsCache[idFa]) {
      const elem = this.findElementWithId(idFa, false, false, true);
      if (elem && elem['label']) {
        this.toolsCache[idFa] = elem['label'];
        console.log(idFa, "addfadfsdfsadafdfsds", elem['label']);
      } else {
        this.toolsCache[idFa] = "";
      }
    }
    return this.toolsCache[idFa];
  }

  public removeIdFaByUser(idFa, isDelete = true) {
    idFa = this.getOriginalFa(idFa);
    if(isDelete) {
      this.deletedFaByUser[idFa] = true;
    } else {
      delete this.deletedFaByUser[idFa];
    }
  }

  public editFAByUser(editing = false) {
    if(this.authService.checkUserIsDeveloping == 1) this.openSnackBar("No es posible editar la pantalla si el usuario es Desarrollador", 3000, ["blue-snackbar"]);
    else {
      this.editingModeByUser = editing;
      if(!editing) {
        this.endpointService.saveDeletedFaByUser(this.authService.userId, this.deletedFaByUser, this.currentInitialArea['key']).subscribe((data) => {
          this.openSnackBar("Guardado con éxito", 3000, ["green-snackbar"]);
        });
      }
    }
  }

  getOriginalFa(idFa) {
    if (idFa.includes(".")) {
      // Si contiene un punto, toma la parte antes del punto
      idFa = parseInt(idFa.split(".")[0], 10);
    } else {
      // Si no tiene un punto, conviértelo directamente a número
      idFa = parseInt(idFa, 10);
    }
    return idFa;
  }

  public removeIdFaByCompany(idFa, isDelete = true) {
    idFa = this.getOriginalFa(idFa);
    if(idFa)
    if(isDelete) {
      this.deletedFaByCompany[idFa] = true;
    } else {
      delete this.deletedFaByCompany[idFa];
    }
  }
  
  public editFAByCompany(editing = false) {
    if(this.authService.checkUserIsDeveloping == 1) this.openSnackBar("No es posible editar la pantalla si el usuario es Desarrollador", 3000, ["blue-snackbar"]);
    else {
      this.editingModeByCompany = editing;
      if(!editing) {
        this.endpointService.saveDeletedFaByCompany(this.authService.getIdCompany(true), this.deletedFaByCompany, this.currentInitialArea['key']).subscribe((data) => {
          this.openSnackBar("Guardado con éxito", 3000, ["green-snackbar"]);
        });
      }
    }
  }

  public finishFunction(finished) {
    if (finished !== null && typeof finished != "string") finished.done = true;
  }

  public addToAdvicebarArray(advicebarRef) {
    this.advicebarArray[this.advicebarId] = advicebarRef;
    this.advicebarId--;
  }

  closeAdvicebar(i) {
    delete this.advicebarArray[i];
  }
  
  public startsWithHashtag = (str: string) => {
    return str.startsWith('#');
  }

  trackByKey(index: number, item: any): any {
    return item.key; // O usa una propiedad única para identificar el elemento
  }

  public openStep(idFA) {
    let fa = this.findElementWithId(1004, true, false, false);
    fa["parent"]["selectedIndex"] = fa["key"];
  }
  
}